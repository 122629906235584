<template>
  <div class="auth-container">
    <div class="auth-card">
      <div>
        <div class="logo-container">
          <img src="@/assets/query-img.svg" alt="Logo" />
        </div>
        <!-- PAGE TITLE -->
        <h5 class="form-title">Créer votre mot de passe</h5>

        <form @submit.prevent="setPassword" class="auth-form">
          <!-- Email display (optional, read-only) -->
          <div class="form-field">
            <input
              type="email"
              class="form-input"
              id="email"
              :value="email"
              disabled
            />
          </div>

          <!-- New Password Field -->
          <div class="form-field">
            <div class="password-container">
              <input
                :type="showPassword ? 'text' : 'password'"
                class="form-input"
                id="password"
                v-model="password"
                required
                autocomplete="new-password"
                :class="{ 'error-input': errorMessage }"
                placeholder="Entrez un mot de passe"
              />
              <img
                class="eye-icon"
                src="@/assets/eye-icon.svg"
                alt="Toggle password visibility"
                @click="togglePassword"
              />
            </div>
          </div>

          <!-- Error Message -->
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>

          <!-- Submit Button with Spinner -->
          <button type="submit" class="auth-button primary-button" :disabled="isLoading">
            <span v-if="!isLoading">Valider</span>
            <div v-else class="spinner-container">
              <div class="spinner"></div>
            </div>
          </button>
        </form>

        <!-- Success / status message -->
        <p class="signup-text" v-if="statusMessage">{{ statusMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { signIn } from '@/firebase/auth'; // Your existing Firebase signIn method

export default {
  name: 'SignupWithTokenPage',
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      errorMessage: '',
      statusMessage: '',
      isLoading: false
    };
  },
  created() {
    // Grab email & token from the URL query params
    this.email = this.$route.query.email || '';
    this.token = this.$route.query.token || '';
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    async setPassword() {
      this.errorMessage = '';
      this.statusMessage = '';

      // Basic checks
      if (!this.token || !this.email) {
        this.errorMessage = "Lien invalide: token ou email manquant.";
        return;
      }
      if (!this.password) {
        this.errorMessage = "Veuillez saisir un mot de passe.";
        return;
      }

      try {
        // Set loading state to true to show spinner
        this.isLoading = true;

        // 1) Call your backend to update password
        const response = await fetch('https://api.query-juriste.com/copilot/api/setPassword', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            token: this.token,
            email: this.email,
            newPassword: this.password
          })
        });

        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || "Erreur lors de la mise à jour du mot de passe");
        }

        // 2) If successful, sign the user in with the new password
        await signIn(this.email, this.password);

        // 3) Redirect to dashboard
        this.$router.replace({ path: '/dashboardPage' });
      } catch (err) {
        this.errorMessage = err.message;
      } finally {
        // Set loading state back to false regardless of outcome
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.auth-container {
  display: flex;
  justify-content: center;
  background: url("@/assets/sign-In-bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.auth-card {
  width: 500px;
  padding: 0 80px;
  background-color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo-container {
  width: 100%;
  height: 130px;
  background: black;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-title {
  text-align: left;
  font-weight: bold;
  margin-top: 40px;
  font-size: 18px;
}

.auth-form {
  margin-top: 20px;
}

.form-field {
  margin-bottom: 20px;
}

.input-label {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  text-align: left;
}

.form-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  height: 40px;
}

.password-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
}

.auth-button {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 15px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary-button {
  background-color: #6c63ff;
  color: white;
}

.primary-button:hover {
  background-color: #5a54d1;
}

.primary-button:disabled {
  background-color: #9991ff;
  cursor: not-allowed;
}

.error-input {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
  text-align: left;
}

.signup-text {
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
  color: #6c63ff;
}

.signup-text a {
  color: #6c63ff;
  text-decoration: none;
}

.signup-text a:hover {
  text-decoration: underline;
}

/* Spinner styles */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .auth-card {
    width: 90%;
    padding: 0 30px;
  }

  .logo-container {
    height: 100px;
  }
}
</style>
