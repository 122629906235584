<template>
  <div>
    <div class="content">
      <!-- Search Section -->
      <div class="search-section">
        <div class="main-section-bottom">
          <div class="container">
            <div class="search-header">
              <div class="search-header-left">
                <!-- Back button (from old page) -->
                <button style="display: flex; margin-bottom: 20px; align-self: flex-start" @click="goDashboard">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-left-circle"
                    viewBox="0 0 16 16"
                    style="margin-right: 5px; margin-top: 4px"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                    />
                  </svg>
                  Retour
                </button>
              </div>
              <div class="search-header-center">
                <div class="search-title-container">
                  <h6 class="search-title"><em>Votre recherche</em></h6>
                  <div class="actions-buttons">
                    <button style = "display: none;" class="button search-button" @click="downloadPDF" :disabled="isDownloadingPDF">
                      {{ isDownloadingPDF ? 'Téléchargement...' : 'Télécharger la synthèse' }}
                      <!-- Spinner shown when downloading -->
                      <svg v-if="isDownloadingPDF" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat spinner" viewBox="0 0 16 16" style="margin-left: 5px;">
                        <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                        <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                      </svg>
                      <!-- PDF icon shown when not downloading -->
                      <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16" style="margin-left: 5px;">
                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"/>
                      </svg>
                    </button>
                    <button class="button search-button" @click="goDashboard">
                      Nouvelle recherche
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16" style="margin-left: 6px;">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <p class="search-query">
                  <strong>{{ dossierData && dossierData.contexte ? dossierData.contexte : 'Chargement de la recherche...' }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Progress Bar Section (from old page) -->
      <div v-if="isAnalysing === 'pending'" class="search-section">
        <div class="main-section-bottom">
          <div class="progress-bar-container">
            <div class="progress">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                :style="{ width: progressPercentage + '%' }"
                :aria-valuenow="progressPercentage"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ Math.round(progressPercentage) }}%
              </div>
            </div>
            <div class="analysis-message loading-message" style="margin-top: 10px;">
              <div class="title-points">
                <p><em>{{ analysisMessage }}</em></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Cadre juridique (Legal Framework) Section - hidden when analysis is pending -->
      <div class="search-section" v-if="isAnalysing !== 'pending'">
        <div class="section-carde">
          <div class="mes-epingles">
            <div class="section-header">
              <h6 class="section-title">Cadre juridique</h6>
            </div>
            <div class="section-content-container" :class="{ 'collapsed': !isExpanded }">
              <div class="section-content">
                <div class="legal-text" v-if="dossierData && dossierData.synthese" v-html="formatText(dossierData.synthese)"></div>
                <!-- Documents clés Section -->
                <div class="section-carde" v-if="keyDocuments && keyDocuments.length > 0">
                  <div class="key-doc-section">
                    <div class="section-header">
                      <h6 class="section-title">Documents décisifs</h6>
                    </div>
                    <div class="section-content">
                      <div v-if="keyDocuments && keyDocuments.length > 0" class="key-documents">
                        <div v-for="(item, idx) in keyDocuments" :key="idx" class="key-document-item">
                          <span
                            class="article-link"
                            @click="openKeyDocument(item)"
                          >
                            {{ getDocumentTitle(item) }}
                            <span class="document-source">({{ getSourceTitle(item.sourceKey) }})</span>
                          </span>
                        </div>
                      </div>
                      <div v-else class="empty-message">
                        Aucun document clé disponible pour ce dossier.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="legal-text" v-else>
                </div>
              </div>
              <div class="fade-overlay" v-if="!isExpanded"></div>
            </div>
            <div class="show-more-container">
              <button class="button show-more-button" @click="toggleExpand">
                {{ isExpanded ? 'Afficher moins' : 'Afficher plus' }}
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi" :class="{ 'bi-chevron-down': !isExpanded, 'bi-chevron-up': isExpanded }" viewBox="0 0 16 16" style="margin-left: 6px;">
                  <path v-if="!isExpanded" fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  <path v-else fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Détails de la recherche (Research Details) Section - hidden when analysis is pending -->
      <div class="search-section" v-if="isAnalysing !== 'pending'">
        <div class="main-section-bottom">
          <div class="researchs">
            <div class="section-header">
              <h6 class="section-title">Détails de la recherche approfondie</h6>
              <button class="button transparent-button drag-button">
                <img src="@/assets/drag.svg" alt="Drag" class="drag-icon">
              </button>
            </div>
            <div class="card-grid" v-if="loading">
              <div class="card-container">
                <div class="card">
                  <p class="card-text">Chargement des analyses...</p>
                </div>
              </div>
            </div>
            <div class="card-grid" v-else-if="error">
              <div class="card-container">
                <div class="card">
                  <p class="card-text">{{ error }}</p>
                </div>
              </div>
            </div>
            <div class="card-grid" v-else-if="dossierData && analysesFiscales && analysesFiscales.length > 0">
              <div class="card-container" v-for="(pointItem, index) in analysesFiscales" :key="index">
                <div class="card">
                  <p class="card-text">{{ pointItem.point }}</p>
                  <div class="card-actions">
                    <button class="button search-button" @click="handleAnalyseComplete(pointItem)">
                      Analyse complète
                      <img src="@/assets/arrow-right-white.svg" alt="Arrow Right" class="arrow-icon">
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-grid" v-else>
              <div class="card-container">
                <div class="card">
                  <p class="card-text">Aucun point d'analyse disponible pour ce dossier.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Legal Analysis Modal -->
    <LegalAnalysisModal
      v-if="selectedPoint"
      :pointData="selectedPoint"
      :isVisible="showAnalysisModal"
      @close="closeAnalysisModal"
    />
  </div>
</template>

<script>
import { auth, db } from "@/firebase/firebaseConfig";
import { doc, getDoc, collection, getDocs, onSnapshot } from 'firebase/firestore';
import LegalAnalysisModal from "./LegalAnalysisModal.vue";
import PdfGenerator from '@/services/PdfGenerator';
import TextFormatter from '@/services/TextFormatter';

export default {
  name: 'DossierPage',
  components: {
    LegalAnalysisModal
  },
  data() {
    return {
      isExpanded: false,
      dossierData: null,
      dossierName: "",
      loading: true,
      error: null,
      showTextModal: false,
      modalTitle: "",
      modalText: "",
      analysesFiscales: [],
      showAnalysisModal: false,
      selectedPoint: null,
      // Added properties from old page for progress bar and loading message
      isAnalysing: null, // 'pending', 'done', or null
      progressPercentage: 0,
      analysisMessage: "",
      progressInterval: null,
      messageInterval: null,
      isDownloadingPDF: false,
      randomDocs: [
        this.getRandomInt(45, 55),   // Replace with this.getRandomInt(45, 55)
        this.getRandomInt(95, 105),  // Replace with this.getRandomInt(95, 105)
        this.getRandomInt(145, 155), // Replace with this.getRandomInt(145, 155)
        this.getRandomInt(195, 205), // Replace with this.getRandomInt(195, 205)
      ],
      // New property for key documents
      keyDocuments: []
    };
  },

  watch: {
    isAnalysing: {
      handler(newValue) {
        if (newValue === "pending") {
          this.startProgressBar();
          this.startMessageInterval();
        } else {
          this.quickCompleteAndStopProgressBar();
          this.stopMessageInterval();
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    // Clean up intervals when component is destroyed
    this.stopProgressBar();
    this.stopMessageInterval();
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },

  async mounted() {
    await this.fetchDossierData();
    // Check analysis status and start listening to changes
    await this.checkAnalysisStatus();
    if (this.isAnalysing === "pending") {
      this.startProgressBar();
      this.startMessageInterval();
    }
    this.listenToAnalysisStatus();
  },

  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },

    goDashboard() {
      this.$router.push({
        name: 'DashboardPage',
      });
    },

    // Format text with markdown-like syntax
    formatText(text) {
      if (!text) return '';

      // Escape any HTML to prevent XSS attacks
      let formattedText = this.escapeHtml(text);

      // Convert line breaks to <br> tags
      formattedText = formattedText.replace(/\n/g, '<br>');

      // Convert **text** to <strong>text</strong>
      formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

      // Convert *text* to <em>text</em> (italics)
      formattedText = formattedText.replace(/\*(.*?)\*/g, '<em>$1</em>');

      // Convert _text_ to <u>text</u> (underline)
      formattedText = formattedText.replace(/_(.*?)_/g, '<u>$1</u>');

      // Convert ## headings
      formattedText = formattedText.replace(/##\s+(.*?)(?:\n|$)/g, '<h3>$1</h3>');

      // Convert # headings
      formattedText = formattedText.replace(/#\s+(.*?)(?:\n|$)/g, '<h2>$1</h2>');

      return formattedText;
    },

    // Escape HTML to prevent XSS attacks
    escapeHtml(text) {
      const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
      };
      return text.replace(/[&<>"']/g, m => map[m]);
    },

    // Handle the click on "Analyse complète" button
    handleAnalyseComplete(pointItem) {
      this.selectedPoint = pointItem;
      this.showAnalysisModal = true;
    },

    // Close the analysis modal
    closeAnalysisModal() {
      this.showAnalysisModal = false;
    },

    // Close the text modal (for potential future implementation)
    closeTextModal() {
      this.showTextModal = false;
    },

    async downloadPDF() {
      try {
        // Set loading state to true
        this.isDownloadingPDF = true;

        // Show loading notification
        if (this.$toast) {
          this.$toast.info("Préparation du PDF en cours...");
        }

        // Generate PDF using the service
        await PdfGenerator.generateDossierPDF(
          this.dossierData,
          this.analysesFiscales,
          TextFormatter.format
        );

      } catch (error) {
        console.error("Erreur lors de la génération du PDF:", error);

        if (this.$toast) {
          this.$toast.error("Erreur lors de la génération du PDF. Veuillez réessayer.");
        }
      } finally {
        // Reset loading state regardless of success or failure
        this.isDownloadingPDF = false;
      }
    },

    /**
     * Extract all documents with note >= 8 from all analyses
     * and deduplicate them based on ID
     */
    extractKeyDocuments() {
      if (!this.analysesFiscales || this.analysesFiscales.length === 0) {
        return [];
      }

      const allKeyDocs = [];

      // Go through each analysis point
      this.analysesFiscales.forEach(pointItem => {
        // Check if pointItem has an 'analyses' array
        if (!pointItem.analyses) return;

        // Go through each source block in the analyses array
        pointItem.analyses.forEach(sourceBlock => {
          if (!sourceBlock.source || !sourceBlock.analysis) return;

          const sourceKey = sourceBlock.source;

          // Go through each document in this source type
          sourceBlock.analysis.forEach((doc, index) => {
            // Check if it's a key document (note >= 8)
            if (doc.note && doc.note >= 8) {
              allKeyDocs.push({
                document: doc,
                sourceKey: sourceKey,
                index: index,
                pointItem: pointItem // Keep reference to the original point item
              });
            }
          });
        });
      });

      // Deduplicate based on id
      const uniqueDocs = [];
      const seenIds = new Set();

      allKeyDocs.forEach(item => {
        if (item.document.id && !seenIds.has(item.document.id)) {
          seenIds.add(item.document.id);
          uniqueDocs.push(item);
        } else {
          // If no ID, use title
          const title = this.getDocumentTitle(item);
          if (!seenIds.has(title)) {
            seenIds.add(title);
            uniqueDocs.push(item);
          }

        }
      });

      return uniqueDocs;
    },

    /**
     * Get a display title for a document
     */
    getDocumentTitle(item) {
      const doc = item.document;
      const index = item.index;

      if (doc.title) return doc.title;

      switch (item.sourceKey) {
        case 'legiarti':
          return doc.textNumber || `Article #${index + 1}`;
        case 'juritext':
          return `Décision #${index + 1}`;
        case 'bofip':
          return `Doctrine #${index + 1}`;
        case 'euritext':
          return `Jurisprudence #${index + 1}`;
        default:
          return `Document #${index + 1}`;
      }
    },

    /**
     * Open the modal for a key document
     */
    openKeyDocument(item) {
      this.selectedPoint = item.pointItem;
      this.showAnalysisModal = true;
    },

    // Fetch dossier data from Firestore
    async fetchDossierData() {
      try {
        const userId = auth.currentUser.uid;
        const dossierId = this.$route.params.dossierId;
        const dossierDocRef = doc(db, "users", userId, "dossiers", dossierId);

        // Fetch the dossier document
        const docSnap = await getDoc(dossierDocRef);
        if (!docSnap.exists()) {
          this.error = "Dossier non trouvé";
          this.loading = false;
          return;
        }

        const dossierData = docSnap.data();

        this.dossierName = dossierData.name || "Dossier sans nom";

        // First, check for the old structure (analyses_fiscales directly in the doc)
        if (dossierData.analyses_fiscales) {
          this.dossierData = dossierData;
          this.analysesFiscales = dossierData.analyses_fiscales;
        } else {
          // Fetch the new structure (analyses in a sub-collection)
          const analysesColRef = collection(dossierDocRef, "analyses");
          const analysesSnap = await getDocs(analysesColRef);

          this.analysesFiscales = analysesSnap.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Store other dossier-level metadata
          this.dossierData = {
            ...dossierData, // Keep the top-level fields
            analyses_fiscales: this.analysesFiscales, // Add fetched analyses for consistency
          };

          console.log("Fetched analyses:", this.dossierData);

        }

        // Extract key documents
        this.keyDocuments = this.extractKeyDocuments();
      } catch (error) {
        this.error = "Erreur lors de la récupération des données du dossier";
      } finally {
        this.loading = false;
      }
    },

    async checkAnalysisStatus() {
      try {
        const userId = auth.currentUser.uid;
        const dossierId = this.$route.params.dossierId;

        const dossierDocRef = doc(db, "users", userId, "dossiers", dossierId);
        const dossierDoc = await getDoc(dossierDocRef);
        if (dossierDoc.exists()) {
          this.isAnalysing = dossierDoc.data().isAnalysing || null;
          this.analyses = dossierDoc.data().points_list || [];
        } else {
          this.isAnalysing = null;
        }
      } catch (error) {
        this.isAnalysing = null;
      }
    },

    listenToAnalysisStatus() {
      const userId = auth.currentUser.uid;
      const dossierId = this.$route.params.dossierId;
      const dossierDocRef = doc(db, "users", userId, "dossiers", dossierId);

      this.unsubscribe = onSnapshot(
        dossierDocRef,
        async (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();

            // Store previous status before updating
            const previousStatus = this.isAnalysing;
            this.isAnalysing = data.isAnalysing;

            // When status changes from "pending" to "done"
            if (previousStatus === "pending" && this.isAnalysing === "done") {

              // Wait a second to make sure Firestore has the latest data
              setTimeout(async () => {
                await this.fetchDossierData(); // Refresh data to get analysis results

                if (this.$toast) {
                  this.$toast.success("L'analyse est terminée. Vous pouvez maintenant consulter la synthèse.");
                }
              }, 1000);
            } else {
              // Update the dossierData object with latest Firestore data for other status changes
              this.dossierData = data;
            }
          }
        },
        (error) => {
          console.error("Error listening to analysis status:", error);
        }
      );
    },

    getSourceTitle(sourceType) {
      const titles = {
        'legiarti': 'Articles de loi',
        'juritext': 'Décisions de justice',
        'bofip': 'Doctrine administrative',
        'euritext': 'Jurisprudence européenne',
        'convbi': 'Conventions'
      };
      return titles[sourceType] || sourceType;
    },

    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    stopMessageInterval() {
      if (this.messageInterval) {
        clearInterval(this.messageInterval);
        this.messageInterval = null;
      }
    },

    startProgressBar() {
      // Reset progress to 0
      this.progressPercentage = 0;

      // New total time: 280 seconds (1.4 times longer than before)
      const totalTime = 280;

      // Capture the start time
      const startTime = Date.now();

      // Clear any existing interval
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
      }

      // Run updates every X ms
      this.progressInterval = setInterval(() => {
        // If analysis is done or no longer "pending", jump to 100 and stop.
        if (this.isAnalysing !== "pending") {
          this.quickCompleteAndStopProgressBar();
          return;
        }

        // Time since we started, in seconds
        const elapsed = (Date.now() - startTime) / 1000;

        let newProgress;

        if (elapsed < 70) {
          // 0 → 70s => 0% → 50%
          const fraction = elapsed / 70;
          newProgress = fraction * 50;
        } else if (elapsed < 116.66) {
          // 70 → 116.66s => 50% → 75%
          const fraction = (elapsed - 70) / 46.66;
          newProgress = 50 + fraction * 25;
        } else if (elapsed < 186.66) {
          // 116.66 → 186.66s => 75% → 90%
          const fraction = (elapsed - 116.66) / 70;
          newProgress = 75 + fraction * 15;
        } else if (elapsed < 280) {
          // 186.66 → 280s => 90% → 99%
          const fraction = (elapsed - 186.66) / 93.34;
          newProgress = 90 + fraction * 9;
        } else {
          // If we've exceeded 280s, just hold at 99%
          newProgress = 99;
        }

        // Update the progress percentage
        this.progressPercentage = newProgress;

        // If we've reached the end of the time (and still "pending"), hold at 99%
        if (elapsed >= totalTime) {
          clearInterval(this.progressInterval);
        }
      }, 50); // update every 50ms for smoother animation
    },

    // 3. Update the startMessageInterval method
    startMessageInterval() {
      if (!this.randomDocs || this.randomDocs.some(value => value === undefined)) {
        this.randomDocs = [
          this.getRandomInt(45, 55),   // Around 50
          this.getRandomInt(95, 105),  // Around 100
          this.getRandomInt(145, 155), // Around 150
          this.getRandomInt(195, 205), // Around 200
        ];
      }

      const startTime = Date.now();

      // Clear any existing interval just to be safe
      if (this.messageInterval) {
        clearInterval(this.messageInterval);
        this.messageInterval = null;
      }

      this.messageInterval = setInterval(() => {
        // Compute elapsed time in seconds
        const elapsed = (Date.now() - startTime) / 1000;

        // Stop if analysis is done or no longer "pending"
        if (this.isAnalysing !== "pending") {
          this.stopMessageInterval();
          return;
        }

        // Now pick which message to display based on elapsed time (adjusted by 1.4x)
        if (elapsed < 7) { // 5 * 1.4 ≈ 7
          this.analysisMessage = "Démarrage de la recherche...";
        } else if (elapsed < 22) { // 16 * 1.4 ≈ 22
          this.analysisMessage = `Nos agents ont lu ${this.randomDocs[0]} documents...`;
        } else if (elapsed < 36) { // 26 * 1.4 ≈ 36
          this.analysisMessage = `Nos agents ont lu ${this.randomDocs[1]} documents...`;
        } else if (elapsed < 52) { // 37 * 1.4 ≈ 52
          this.analysisMessage = `Nos agents ont lu ${this.randomDocs[2]} documents...`;
        } else if (elapsed < 76) { // 54 * 1.4 ≈ 76
          this.analysisMessage = `Nos agents ont lu ${this.randomDocs[3]} documents...`;
        } else if (elapsed < 98) { // 70 * 1.4 ≈ 98
          this.analysisMessage = "Extraction d'informations pertinentes...";
        } else if (elapsed < 168) { // 120 * 1.4 ≈ 168
          this.analysisMessage = "Génération de réponse...";
        } else {
          this.analysisMessage = "Génération de réponse...";
        }
      }, 1000); // check every 1 second
    },

    quickCompleteAndStopProgressBar() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
        this.progressPercentage = 100;
        setTimeout(() => {
          this.progressPercentage = 0;
        }, 500);
      }
    },

    stopProgressBar() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
      }
    }
  },
};
</script>

<style scoped>

.actions-buttons {
  display: flex;
  gap: 10px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.download-button {
  background-color: #4f46e5;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #4338ca;
}

.download-button[disabled] {
  background-color: #a5a5a5;
  cursor: not-allowed;
}

.download-button svg {
  margin-right: 6px;
}

.key-doc-section {
  margin-top: 2em;
}

/* Base styles */
.content {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  background-color: #F5F6FA;
  min-height: 100vh;
  width: 100vw;
}

.search-section {
  display: grid;
  place-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

/* Container widths */
.main-section-bottom {
  width: 90%;
}

.section-carde {
  width: 90%;
}

/* Headers and section layouts */
.container {
  width: 100%;
}

.search-header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  gap: 20px;
  width: 100%;
}

.search-header-center {
  text-align: left;
}

.search-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.search-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-title {
  margin: 0;
  margin-right: 8px;
}

.search-timestamp {
  font-size: 12px;
  color: #939393;
}

.search-query {
  margin-top: 16px;
  text-align: left;
  font-size: 1em;
}

.edit-icon {
  width: 12px;
  height: 12px;
  margin-bottom: 4px;
}

/* Section styles */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.section-title {
  font-weight: 700;
  text-align: left;
  margin: 0;
}

.section-content {
  margin-top: 24px;
}

/* Card sections */
.mes-epingles {
  max-width: 90vw;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  border: 0.5px solid #BBBBBB;
  box-shadow:
    0 -2px 4px rgba(0, 0, 0, 0.01),
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 8px 16px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
}

.researchs {
  background: linear-gradient(to right, #E9EFF4, #E2FAFFCC);
  box-shadow:
    0 -2px 4px rgba(0, 0, 0, 0.01),
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px 50px 25px;
  border-radius: 8px;
  margin-top: 32px;
}

/* Card grids */
.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 24px;
  width: 100%;
}

.card-small-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-top: 24px;
  width: 100%;
}

/* Cards */
.card {
  font-size: 0.8rem;
  background-color: white;
  border-radius: 20px;
  box-shadow:
    0 -2px 4px rgba(0, 0, 0, 0.01),
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 6px 12px rgba(0, 0, 0, 0.05);
  color: #000;
  font-weight: 500;
  padding: 16px;
}

.card-container {
  margin-bottom: 16px;
}

.card-text {
  text-align: left;
  margin-top: 8px;
}

.download-button svg {
  margin-right: 6px;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.card-actions {
  display: flex;
  margin-top: 10px;
}

.card-bookmark {
  text-align: right;
}

/* Text styles */
.legal-text {
  text-align: left;
  font-size: 12px;
  line-height: 1.5;
}

.legal-text h2 {
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 600;
}

.legal-text h3 {
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 6px;
  font-weight: 600;
}

.legal-text br {
  display: block;
  margin-top: 8px;
  content: "";
}

/* Buttons */
.button {
  padding: 8px 25px;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.search-button {
  background-color: #6c63ff;
  color: white;
  font-size: 12px;
  padding: 5px 25px;
}

.search-button:hover {
  background-color: #5a54d1;
}

.transparent-button {
  background: transparent;
  padding: 0;
}

.drag-button:focus {
  outline: none;
  box-shadow: none;
}

/* Icons */
.search-icon, .arrow-icon {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.drag-icon {
  width: 16px;
  height: 16px;
}

.action-icons {
  text-align: left;
}

.action-icon {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.bookmark-icon {
  width: 12px;
}

/* Key documents styles */
.key-documents {
  margin-top: 16px;
}

.key-document-item {
  margin-bottom: 12px;
  text-align: left;
}

.article-link {
  color: #625CFB;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  line-height: 1.5;
}

.article-link:hover {
  color: #4338ca;
}

.document-source {
  color: #6b7280;
  font-style: italic;
  font-size: 11px;
}

.empty-message {
  color: #888;
  font-style: italic;
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .search-header {
    grid-template-columns: 1fr;
  }

  .search-header-right {
    justify-content: flex-start;
    margin-top: 16px;
  }

  .card-grid {
    grid-template-columns: 1fr;
  }

  .card-small-grid {
    grid-template-columns: 1fr 1fr;
  }

  .main-section-bottom, .section-carde {
    width: 95%;
  }
}

/* Show more/less functionality styles */
.section-content-container {
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.section-content-container.collapsed {
  max-height: 150px; /* This represents approximately 30% of the content */
}

.fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none;
}

.show-more-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.show-more-button {
  background-color: #f0f0f0;
  color: #6c63ff;
  font-size: 12px;
  border: 1px solid #e0e0e0;
  transition: background-color 0.3s;
}

.show-more-button:hover {
  background-color: #e6e6e6;
}

/* Styles for progress bar and loading message */
.progress-bar-container {
  margin-top: 3em;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.progress {
  height: 20px;
  background-color: lightgrey;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #6c63ff;
  color: white;
  text-align: center;
  line-height: 20px;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@keyframes progress-bar-stripes {
  from { background-position: 1rem 0; }
  to { background-position: 0 0; }
}

.loading-message {
  color: transparent;
  background: linear-gradient(to left, lightgray 50%, black 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  animation: slide 6s linear infinite;
  cursor: default;
  pointer-events: none;
}

@keyframes slide {
  to {
    background-position: -100%;
  }
}

.title-points {
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.analysis-message {
  font-size: 1.1em;
  text-align: center;
  margin: 15px 0;
}
</style>
