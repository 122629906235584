<template>
  <div class="auth-container">
    <div class="auth-card">
      <div>
        <div class="logo-container">
          <img src="@/assets/query-img.svg" alt="Logo" />
        </div>
        <h5 class="form-title">Réinitialisation du mot de passe</h5>

        <div v-if="loading" class="loading-message">
          Vérification de votre lien...
        </div>

        <div v-else-if="error" class="error-container">
          <div class="error-message">{{ errorMessage }}</div>
          <router-link to="/forgot-password" class="auth-button primary-button">
            Demander un nouveau lien
          </router-link>
        </div>

        <form v-else-if="!resetComplete" @submit.prevent="resetPassword" class="auth-form">
          <div class="form-field">
            <label class="input-label" for="password">Nouveau mot de passe</label>
            <div class="password-container">
              <input
                :type="showPassword ? 'text' : 'password'"
                class="form-input"
                id="password"
                v-model="password"
                required
                autocomplete="new-password"
                :class="{ 'error-input': passwordError }"
                placeholder="Entrez votre nouveau mot de passe"
              />
              <img
                class="eye-icon"
                src="@/assets/eye-icon.svg"
                alt="Toggle password"
                @click="togglePassword"
              />
            </div>
          </div>

          <div class="form-field">
            <label class="input-label" for="confirmPassword">Confirmer le mot de passe</label>
            <div class="password-container">
              <input
                :type="showConfirmPassword ? 'text' : 'password'"
                class="form-input"
                id="confirmPassword"
                v-model="confirmPassword"
                required
                autocomplete="new-password"
                :class="{ 'error-input': passwordError }"
                placeholder="Confirmez votre nouveau mot de passe"
              />
              <img
                class="eye-icon"
                src="@/assets/eye-icon.svg"
                alt="Toggle password"
                @click="toggleConfirmPassword"
              />
            </div>
            <div v-if="passwordError" class="error-message">
              {{ passwordErrorMessage }}
            </div>
          </div>

          <button type="submit" class="auth-button primary-button" :disabled="isSubmitting">
            {{ isSubmitting ? 'Réinitialisation...' : 'Réinitialiser le mot de passe' }}
          </button>
        </form>

        <div v-else class="success-container">
          <div class="success-message">
            Mot de passe réinitialisé avec succès!
          </div>
          <router-link to="/loginPage" class="auth-button primary-button">
            Se connecter
          </router-link>
        </div>
      </div>
      <div class="copyright">© Query Juriste 2025</div>
    </div>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.auth-container {
  display: flex;
  justify-content: center;
  background: url("@/assets/sign-In-bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.auth-card {
  width: 500px;
  padding: 0 80px;
  background-color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo-container {
  width: 100%;
  height: 130px;
  background: black;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-title {
  text-align: left;
  font-weight: bold;
  margin-top: 40px;
  font-size: 18px;
}

.auth-form {
  margin-top: 20px;
}

.form-field {
  margin-bottom: 20px;
}

.input-label {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  text-align: left;
}

.form-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  height: 40px;
}

.password-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
}

.auth-button {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
}

.primary-button {
  background-color: #6c63ff;
  color: white;
  display: inline-block;
  text-decoration: none;
}

.primary-button:hover {
  background-color: #5a54d1;
}

.primary-button:disabled {
  background-color: #a5a0ff;
  cursor: not-allowed;
}

.copyright {
  font-size: 12px;
  margin-bottom: 40px;
  text-align: center;
}

.error-input {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
  text-align: left;
}

.success-message {
  color: green;
  font-size: 1em;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.error-container, .success-container {
  text-align: center;
  margin: 30px 0;
}

.loading-message {
  text-align: center;
  margin: 30px 0;
  font-size: 1em;
  color: #666;
}

@media (max-width: 768px) {
  .auth-card {
    width: 90%;
    padding: 0 30px;
  }

  .logo-container {
    height: 100px;
  }
}
</style>

<script>
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { app } from '@/firebase/firebaseConfig';

export default {
  data() {
    return {
      oobCode: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      passwordError: false,
      passwordErrorMessage: '',
      loading: true,
      error: false,
      errorMessage: '',
      resetComplete: false,
      isSubmitting: false
    };
  },
  created() {
    // Extract the action code from the URL
    this.oobCode = this.$route.query.oobCode;

    if (!this.oobCode) {
      this.error = true;
      this.errorMessage = "Lien invalide. Veuillez demander un nouveau lien de réinitialisation.";
      this.loading = false;
      return;
    }

    // Verify the action code
    const auth = getAuth(app);
    verifyPasswordResetCode(auth, this.oobCode)
      .then(() => {
        // Code is valid
        this.loading = false;
      })
      .catch((error) => {
        console.error("Error verifying reset code:", error);
        this.error = true;
        this.loading = false;

        switch (error.code) {
          case 'auth/expired-action-code':
            this.errorMessage = "Ce lien a expiré. Veuillez demander un nouveau lien de réinitialisation.";
            break;
          case 'auth/invalid-action-code':
            this.errorMessage = "Ce lien est invalide. Il a peut-être déjà été utilisé.";
            break;
          default:
            this.errorMessage = "Une erreur s'est produite. Veuillez demander un nouveau lien de réinitialisation.";
            break;
        }
      });
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    async resetPassword() {
      // Reset errors
      this.passwordError = false;
      this.passwordErrorMessage = '';

      // Validate password
      if (this.password.length < 6) {
        this.passwordError = true;
        this.passwordErrorMessage = "Le mot de passe doit contenir au moins 6 caractères.";
        return;
      }

      // Validate password match
      if (this.password !== this.confirmPassword) {
        this.passwordError = true;
        this.passwordErrorMessage = "Les mots de passe ne correspondent pas.";
        return;
      }

      this.isSubmitting = true;

      // Confirm password reset
      const auth = getAuth(app);
      try {
        await confirmPasswordReset(auth, this.oobCode, this.password);
        this.resetComplete = true;
      } catch (error) {
        console.error("Error confirming password reset:", error);
        this.passwordError = true;

        switch (error.code) {
          case 'auth/weak-password':
            this.passwordErrorMessage = "Ce mot de passe est trop faible. Choisissez un mot de passe plus fort.";
            break;
          case 'auth/expired-action-code':
            this.passwordErrorMessage = "Ce lien a expiré. Veuillez demander un nouveau lien de réinitialisation.";
            break;
          case 'auth/invalid-action-code':
            this.passwordErrorMessage = "Ce lien est invalide. Il a peut-être déjà été utilisé.";
            break;
          default:
            this.passwordErrorMessage = "Une erreur s'est produite. Veuillez réessayer.";
            break;
        }
      } finally {
        this.isSubmitting = false;
      }
    }
  }
}
</script>
