<template>
  <div class="auth-container">
    <div class="auth-card">
      <div>
        <div class="logo-container">
          <img src="@/assets/query-img.svg" alt="Logo" />
        </div>
        <h5 class="form-title">Inscription</h5>
        <form @submit.prevent="signin" class="auth-form">
          <div class="form-field">
            <label class="input-label" for="email"></label>
            <input
              type="email"
              class="form-input"
              id="email"
              v-model="email"
              required
              autocomplete="email"
              :class="{ 'error-input': loginError }"
              placeholder="Votre email"
            />
          </div>

          <div class="form-field">
            <label class="input-label" for="password"></label>
            <div class="password-container">
              <input
                :type="showPassword ? 'text' : 'password'"
                class="form-input"
                id="password"
                v-model="password"
                required
                autocomplete="new-password"
                :class="{ 'error-input': loginError }"
                placeholder="Votre mot de passe"
              />
              <img
                class="eye-icon"
                src="@/assets/eye-icon.svg"
                alt="Toggle password"
                @click="togglePassword"
              />
            </div>
            <div v-if="loginError" class="error-message">
              Il semble que cet e-mail est déjà utilisé
            </div>
          </div>

          <button type="submit" class="auth-button primary-button">Inscription</button>

          <div class="divider"></div>

          <button type="button" @click="signInWithGoogle" class="auth-button google-button">
            <img src="@/assets/google-logo.svg" alt="Google Logo" class="google-logo" />
            Login with Google
          </button>

          <p class="signup-text">
            Déjà un compte ? <router-link to="/loginPage">Se connecter</router-link>
          </p>
        </form>
      </div>
      <div class="copyright">© Query Juriste 2025</div>
    </div>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.auth-container {
  display: flex;
  justify-content: center;
  background: url("@/assets/sign-In-bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.auth-card {
  width: 500px;
  padding: 0 80px;
  background-color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo-container {
  width: 100%;
  height: 130px;
  background: black;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-title {
  text-align: left;
  font-weight: bold;
  margin-top: 40px;
  font-size: 18px;
}

.auth-form {
  margin-top: 20px;
}

.form-field {
  margin-bottom: 20px;
}

.input-label {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  text-align: left;
}

.form-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  height: 40px;
}

.password-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.remember-container {
  display: flex;
  align-items: center;
}

.remember-checkbox {
  margin-right: 5px;
  background-color: #f2f2f2;
}

.remember-label {
  font-size: 12px;
}

.auth-button {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 15px;
}

.primary-button {
  background-color: #6c63ff;
  color: white;
}

.primary-button:hover {
  background-color: #5a54d1;
}

.divider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 30px 0;
}

.google-button {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-logo {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.signup-text {
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
}

.signup-text a {
  color: #6c63ff;
  text-decoration: none;
}

.signup-text a:hover {
  text-decoration: underline;
}

.copyright {
  font-size: 12px;
  margin-bottom: 40px;
  text-align: center;
}

.error-input {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
  text-align: left;
}

@media (max-width: 768px) {
  .auth-card {
    width: 90%;
    padding: 0 30px;
  }

  .logo-container {
    height: 100px;
  }
}
</style>

<script>
import { signUp, signInWithGoogle } from '@/firebase/auth';

export default {
  data() {
    return {
      email: '',
      password: '',
      acceptTerms: false,
      showPassword: false,
      loginError: false
    };
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    async signInWithGoogle() {
      try {
        await signInWithGoogle();
        this.$router.replace({ name: 'dashboard' });
      } catch (error) {
        alert(error.message);
      }
    },

    async signin() {
      try {
        await signUp(this.email, this.password);
        this.$router.replace({ name: 'dashboard' });
        this.loginError = false;
      } catch (error) {
        this.loginError = true;
      }
    }
  }
}
</script>
