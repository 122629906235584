<template>
  <div class="auth-container">
    <div class="auth-card">
      <div>
        <div class="logo-container">
          <img src="@/assets/query-img.svg" alt="Logo" />
        </div>
        <h5 class="form-title">Réinitialisation du mot de passe</h5>
        <form @submit.prevent="sendResetEmail" class="auth-form">
          <div class="form-field">
            <label class="input-label" for="email"></label>
            <input
              type="email"
              class="form-input"
              id="email"
              v-model="email"
              required
              autocomplete="email"
              :class="{ 'error-input': resetError }"
              placeholder="Votre email"
            />
            <div v-if="resetError" class="error-message">
              {{ errorMessage }}
            </div>
          </div>

          <button type="submit" class="auth-button primary-button" :disabled="isLoading">
            {{ isLoading ? 'Envoi...' : 'Envoyer le lien de réinitialisation' }}
          </button>

          <div v-if="emailSent" class="success-message">
            Un e-mail de réinitialisation a été envoyé à votre adresse e-mail.
          </div>

          <p class="signup-text">
            <router-link to="/loginPage">Retour à la connexion</router-link>
          </p>
        </form>
      </div>
      <div class="copyright">© Query Juriste 2025</div>
    </div>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.auth-container {
  display: flex;
  justify-content: center;
  background: url("@/assets/sign-In-bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.auth-card {
  width: 500px;
  padding: 0 80px;
  background-color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo-container {
  width: 100%;
  height: 130px;
  background: black;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-title {
  text-align: left;
  font-weight: bold;
  margin-top: 40px;
  font-size: 18px;
}

.auth-form {
  margin-top: 20px;
}

.form-field {
  margin-bottom: 20px;
}

.input-label {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  text-align: left;
}

.form-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  height: 40px;
}

.auth-button {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 15px;
}

.primary-button {
  background-color: #6c63ff;
  color: white;
}

.primary-button:hover {
  background-color: #5a54d1;
}

.primary-button:disabled {
  background-color: #a5a0ff;
  cursor: not-allowed;
}

.signup-text {
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
}

.signup-text a {
  color: #6c63ff;
  text-decoration: none;
}

.signup-text a:hover {
  text-decoration: underline;
}

.copyright {
  font-size: 12px;
  margin-bottom: 40px;
  text-align: center;
}

.error-input {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
  text-align: left;
}

.success-message {
  color: green;
  font-size: 0.9em;
  margin-top: 15px;
  text-align: center;
  background-color: rgba(0, 128, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .auth-card {
    width: 90%;
    padding: 0 30px;
  }

  .logo-container {
    height: 100px;
  }
}
</style>

<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { app } from '@/firebase/firebaseConfig';

export default {
  data() {
    return {
      email: '',
      resetError: false,
      errorMessage: '',
      emailSent: false,
      isLoading: false
    };
  },
  methods: {
    async sendResetEmail() {
      this.isLoading = true;
      this.resetError = false;

      const auth = getAuth(app);

      // Define action code settings with custom URL
      const actionCodeSettings = {
        url: `${window.location.origin}/reset-password`,
        handleCodeInApp: true
      };

      try {
        await sendPasswordResetEmail(auth, this.email, actionCodeSettings);
        this.emailSent = true;
      } catch (error) {
        this.resetError = true;

        switch (error.code) {
          case 'auth/user-not-found':
            this.errorMessage = "Aucun compte trouvé avec cette adresse e-mail.";
            break;
          case 'auth/invalid-email':
            this.errorMessage = "Adresse e-mail invalide.";
            break;
          default:
            this.errorMessage = "Une erreur s'est produite. Veuillez réessayer plus tard.";
            break;
        }

        console.error("Error sending password reset email:", error);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>
