<template>
  <div class="modal-overlay" v-if="showModal">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <div class="header-row">
          <div class="header-left"></div>
          <div class="tabs">
            <button
              v-for="tab in availableTabs"
              :key="tab.id"
              :class="['tab-button', { active: activeTab === tab.id }]"
              @click="changeTab(tab.id)"
            >
              <span>{{ tab.label }}</span>
            </button>
          </div>
          <div class="header-right">
            <button class="close-button" @click="closeModal">
              <span>✕</span>
            </button>
          </div>
        </div>
      </div>

      <!-- Modal Body -->
      <div class="tab-content">
        <h2 class="content-title"><strong>{{ pointData.point }}</strong></h2>
        <div class="main-content-container">
          <div class="main-content">

            <!-- Réponse Tab Content -->
            <div v-if="activeTab === 'response'" class="article-content">
              <div v-html="formatText(pointData.final_answer, false)" @click.prevent="handleInlineReferenceClick"></div>
            </div>

            <!-- Bofip Tab Content -->
            <div v-if="activeTab === 'bofip'" class="article-content">
              <div class="source-block" v-if="getBofipSources().length">
                <div class="source-items">
                  <div v-for="(doc, docIndex) in getBofipSources()" :key="docIndex" class="source-item">
                    <span
                      class="article-link"
                      @click="showArticleDetails({
                        title: doc.title || `Document #${docIndex + 1}`,
                        content: doc.content,
                        source: 'bofip',
                        url: doc.url,
                        sourceData: doc
                      })"
                    >
                      {{ docIndex + 1 }}. {{ doc.title || `Document #${docIndex + 1}` }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-else class="empty-message">
                Aucun document Bofip disponible pour ce point.
              </div>
            </div>

            <!-- Euritext Tab Content -->
            <div v-if="activeTab === 'euritext'" class="article-content">
              <div class="source-block" v-if="getEuritextSources().length">
                <div class="source-items">
                  <div v-for="(doc, docIndex) in getEuritextSources()" :key="docIndex" class="source-item">
                    <span
                      class="article-link"
                      @click="showArticleDetails({
                        title: doc.title || `Document #${docIndex + 1}`,
                        content: doc.content,
                        source: 'euritext',
                        id: doc.id,
                        sourceData: doc
                      })"
                    >
                      {{ docIndex + 1 }}. {{ doc.title || `Document #${docIndex + 1}` }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-else class="empty-message">
                Aucune jurisprudence européenne disponible pour ce point.
              </div>
            </div>

            <!-- Juritext Tab Content -->
            <div v-if="activeTab === 'juritext'" class="article-content">
              <div class="source-block" v-if="getJuritextSources().length">
                <div class="source-items">
                  <div v-for="(doc, docIndex) in getJuritextSources()" :key="docIndex" class="source-item">
                    <span
                      class="article-link"
                      @click="showArticleDetails({
                        title: doc.title || `Décision #${docIndex + 1}`,
                        content: doc.summary,
                        source: 'juritext',
                        id: doc.id,
                        texte: doc.texte,
                        sourceData: doc
                      })"
                    >
                      {{ docIndex + 1 }}. {{ doc.title || `Décision #${docIndex + 1}` }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-else class="empty-message">
                Aucune décision de justice disponible pour ce point.
              </div>
            </div>

            <!-- Legiarti (Loi) Tab Content -->
            <div v-if="activeTab === 'legiarti'" class="article-content">
              <div class="source-block" v-if="getLegiartiSources().length">
                <div class="source-items">
                  <div v-for="(doc, docIndex) in getLegiartiSources()" :key="docIndex" class="source-item">
                    <span
                      class="article-link"
                      @click="showArticleDetails({
                        title: doc.title || doc.textNumber || `Article #${docIndex + 1}`,
                        content: doc.content,
                        source: 'legiarti',
                        hierarchy: doc.hierarchy,
                        id: doc.id,
                        annotation: doc.annotation,
                        sourceData: doc
                      })"
                    >
                      {{ docIndex + 1 }}. {{ doc.title || doc.textNumber || `Article #${docIndex + 1}` }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-else class="empty-message">
                Aucun article de loi disponible pour ce point.
              </div>
            </div>
          </div>
          <!-- Article Details Panel -->
          <div class="article-details bg-blue-color" v-if="showArticlePanel">
            <div class="details-header">
              <h3>{{ articleDetails.title }}</h3>
              <button class="close-details" @click="closeArticleDetails">✕</button>
            </div>
            <div class="details-content" v-if="articleDetails.source === 'legiarti'">
              <div v-if="articleDetails.hierarchy" class="hierarchy">
                <strong>{{ formatHierarchy(articleDetails.hierarchy) }}</strong>
              </div>
              <div v-html="formatText(articleDetails.content, true)"></div>

              <!-- Add annotation section if it exists -->
              <div v-if="articleDetails.annotation" class="annotation-section">
                <h4 class="annotation-title">Annotation Jurisprudentielle de l'article</h4>
                <div v-html="formatText(articleDetails.annotation, true)" class="annotation-content"></div>
              </div>
            </div>
            <div class="details-content" v-else-if="articleDetails.source === 'juritext'">
              <div v-if="articleDetails.texte" class="full-decision">
                <a href="#" @click.prevent="openTextModal(articleDetails.texte, articleDetails.id, articleDetails.title)">Voir décision complète</a>
                <div v-html="formatText(articleDetails.content, true)"></div>
              </div>
              <div v-else v-html="formatText(articleDetails.content, true)"></div>
            </div>
            <div class="details-content" v-else-if="articleDetails.source === 'bofip'">
              <div v-if="articleDetails.url" class="source-url">
                <a :href="articleDetails.url" target="_blank" rel="noopener noreferrer">
                  <strong>Voir document complet</strong>
                </a>
              </div>
              <div v-html="formatText(articleDetails.content, true)"></div>
            </div>
            <div class="details-content" v-else-if="articleDetails.source === 'euritext'">
              <div v-if="articleDetails.sourceData && articleDetails.sourceData.links">
                <div class="euritext-links">
                  <strong>Documents associés:</strong>
                  <div v-for="(url, title, index) in articleDetails.sourceData.links" :key="index" class="euritext-link">
                    <a :href="url" target="_blank" rel="noopener noreferrer">{{ title }}</a>
                  </div>
                </div>
              </div>
              <div v-html="formatText(articleDetails.content, true)"></div>
            </div>
            <div class="details-content" v-else>
              <div v-html="formatText(articleDetails.content, true)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TextModal Component -->
    <TextModal
      ref="textModal"
      :isVisible="showTextModal"
      :title="modalTitle"
      :text="modalText"
      :citation="modalCitation"
      @close="closeTextModal"
    />
  </div>
</template>

<script>
import Fuse from 'fuse.js';  // À installer avec: npm install fuse.js

export default {
  name: 'LegalAnalysisModal',
  components: {
    TextModal: () => import('./TextModal.vue')
  },
  props: {
    pointData: {
      type: Object,
      required: true
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
      activeTab: 'response',
      showArticlePanel: false,
      isBookmarked: false,
      showTextModal: false,
      modalText: '',
      modalTitle: '',
      modalCitation: '',
      tabs: [
        { id: 'response', label: 'Réponse' },
        { id: 'legiarti', label: 'Articles de loi' },
        { id: 'juritext', label: 'Décisions de justice' },
        { id: 'bofip', label: 'Doctrine administrative' },
        { id: 'euritext', label: 'Jurisprudence européenne' }
      ],
      articleDetails: {
        title: '',
        content: '',
        source: '',
        hierarchy: '',
        id: '',
        texte: '',
        url: '',
        sourceData: null
      },
      sourceLookup: {},
      indexLookup: {},
      extractedKeywords: [] // Pour stocker les mots-clés extraits
    };
  },
  computed: {
    availableTabs() {
      const tabs = [this.tabs[0]];
      const sources = ['legiarti', 'juritext', 'bofip', 'euritext'];

      sources.forEach(source => {
        const sourceItems = this.getSourceItems(source);
        if (sourceItems.length > 0) {
          const tab = this.tabs.find(t => t.id === source);
          if (tab) {
            tabs.push(tab);
          }
        }
      });

      return tabs;
    }
  },
  watch: {
    isVisible(val) {
      this.showModal = val;
      this.toggleBodyScroll(!val);
    },
    showModal(val) {
      this.toggleBodyScroll(!val);
    },
    pointData(newVal) {
      if (newVal) {
        this.activeTab = 'response';
        this.showArticlePanel = false;
        this.buildSourceIndexes();

        // Extraire les mots-clés de la question
        if (newVal.point) {
          this.extractedKeywords = this.extractKeywords(newVal.point);
        }
      }
    },
    availableTabs(newTabs) {
      if (!newTabs.some(tab => tab.id === this.activeTab)) {
        this.activeTab = 'response';
      }
    },
    showArticlePanel(isVisible) {
      if (isVisible) {
        // Appliquer le surlignage uniquement lorsque le panneau est visible
        this.$nextTick(() => {
          this.applyKeywordHighlighting();
        });
      }
    }
  },
  mounted() {
    this.showModal = this.isVisible;
    if (this.showModal) {
      this.toggleBodyScroll(false);
    }
    this.buildSourceIndexes();

    // Extraire les mots-clés initiaux si pointData est disponible
    if (this.pointData && this.pointData.point) {
      this.extractedKeywords = this.extractKeywords(this.pointData.point);
    }
  },
  beforeDestroy() {
    if (this.showModal) {
      this.toggleBodyScroll(true);
    }
  },
  methods: {
    // ========== Méthodes d'origine ==========
    closeModal() {
      this.showModal = false;
      this.showArticlePanel = false;
      this.$emit('close');
    },

    changeTab(tabId) {
      this.activeTab = tabId;
      this.showArticlePanel = false;
    },

    toggleBookmark() {
      this.isBookmarked = !this.isBookmarked;
    },

    showArticleDetails(details) {
      this.articleDetails = details;
      this.showArticlePanel = true;
      // Le surlignage sera appliqué via le watcher showArticlePanel
    },

    closeArticleDetails() {
      this.showArticlePanel = false;
    },

    async openTextModal(texte, decisionId, title) {
      this.showTextModal = true;
      this.modalText = texte;
      this.modalTitle = title;

      this.$nextTick(() => {
        if (this.$refs.textModal) {
          this.$refs.textModal.highlightCitation();
        }
      });
    },

    closeTextModal() {
      this.showTextModal = false;
    },

    formatHierarchy(hierarchy) {
      if (!hierarchy) return '';
      return hierarchy
        .split(' > ')
        .map(section => section.trim())
        .join(' > ');
    },

    // ========== Méthodes de surlignage ==========
    // Extraire les termes clés d'une question
    extractKeywords(text) {
      if (!text) return [];

      // Liste des mots vides français
      const stopWords = ['le', 'la', 'les', 'un', 'une', 'des', 'et', 'ou', 'en', 'à', 'de', 'du', 'ce', 'cette',
        'ces', 'est', 'sont', 'pour', 'dans', 'par', 'sur', 'au', 'aux', 'avec', 'qui', 'que', 'quoi', 'dont',
        'où', 'comment', 'quand', 'pourquoi', 'quel', 'quelle', 'quels', 'quelles', 'ne', 'pas', 'plus', 'moins',
        'si', 'je', 'tu', 'il', 'elle', 'nous', 'vous', 'ils', 'elles', 'se', 'me', 'te', 'lui', 'leur', 'y'];

      // Extraction des mots significatifs (au moins 3 caractères)
      const words = text.toLowerCase().match(/\b[a-zàáâäæçèéêëìíîïòóôöùúûüÿœ]{3,}\b/g) || [];
      const filteredWords = words.filter(word => !stopWords.includes(word));

      return [...new Set(filteredWords)]; // Éliminer les doublons
    },

    // Expansion sémantique par thésaurus juridique
    expandKeywords(keywords) {
      // Thésaurus juridique simplifié (à compléter selon vos besoins)
      const legalThesaurus = {
        'licenciement': ['rupture', 'résiliation', 'congédiement', 'révocation', 'destitution'],
        'licéité': ['légalité', 'validité', 'conformité', 'régularité'],
        'travail': ['emploi', 'fonction', 'activité', 'profession'],
        'contrat': ['convention', 'accord', 'engagement', 'pacte'],
        'droit': ['prérogative', 'faculté', 'pouvoir', 'autorisation'],
        'obligation': ['devoir', 'engagement', 'contrainte', 'responsabilité'],
        'salarié': ['employé', 'travailleur', 'collaborateur', 'personnel'],
        'employeur': ['patron', 'entreprise', 'société', 'direction'],
        'indemnité': ['compensation', 'dédommagement', 'réparation', 'allocation'],
        'procédure': ['processus', 'démarche', 'formalité', 'protocole'],
        'impôt': ['taxe', 'contribution', 'prélèvement', 'imposition'],
        'fiscal': ['tributaire', 'imposable', 'contributif']
      };

      return keywords.flatMap(term => {
        // Recherche insensible à la casse et aux accents
        const normalizedTerm = term.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        for (const [key, synonyms] of Object.entries(legalThesaurus)) {
          const normalizedKey = key.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          if (normalizedKey === normalizedTerm) {
            return [term, ...synonyms];
          }
        }
        return [term];
      });
    },

    // Configuration de la recherche approximative avec Fuse.js
    setupFuzzySearch(content) {
      if (!content) return null;

      const contentChunks = content.split(/\b/);
      return new Fuse(contentChunks, {
        includeScore: true,
        threshold: 0.3, // Seuil de tolérance
        ignoreLocation: true,
        distance: 200,
        minMatchCharLength: 3,
        findAllMatches: true,
        useExtendedSearch: true
      });
    },

    // Surlignage des termes avec gestion des chevauchements
    highlightKeywords(content, keywords) {
      if (!content || !keywords.length) return content;

      // Élargissement de l'ensemble de termes à rechercher
      const expandedKeywords = this.expandKeywords(keywords);

      // Préparation du texte pour le surlignage
      let highlightedContent = content;
      let matches = [];

      // Recherche simple de correspondances exactes
      expandedKeywords.forEach(keyword => {
        const regex = new RegExp(`\\b${this.escapeRegExp(keyword)}\\b`, 'gi');
        let match;
        while ((match = regex.exec(highlightedContent)) !== null) {
          matches.push({
            start: match.index,
            end: match.index + match[0].length,
            text: match[0]
          });
        }
      });

      // Si aucune correspondance exacte, essayer la recherche approximative
      if (matches.length === 0) {
        const fuse = this.setupFuzzySearch(highlightedContent);
        if (fuse) {
          expandedKeywords.forEach(keyword => {
            const fuzzyMatches = fuse.search(keyword);
            fuzzyMatches.slice(0, 5).forEach(match => { // Limiter aux 5 meilleurs résultats
              if (match.score < 0.3) { // Seuil de confiance
                const idx = match.refIndex;
                const matchedWord = fuse._docs[idx];
                const start = highlightedContent.indexOf(matchedWord);
                if (start >= 0) {
                  const end = start + matchedWord.length;
                  matches.push({
                    start,
                    end,
                    text: matchedWord
                  });
                }
              }
            });
          });
        }
      }

      // Fusion des chevauchements
      matches = this.mergeRanges(matches);

      // Application du surlignage en commençant par la fin pour éviter de décaler les indices
      let result = highlightedContent;
      matches.sort((a, b) => b.start - a.start); // Tri décroissant
      matches.forEach(match => {
        const before = result.substring(0, match.start);
        const term = result.substring(match.start, match.end);
        const after = result.substring(match.end);
        result = before + `<span class="keyword-highlight">${term}</span>` + after;
      });

      return result;
    },

    // Fusion des plages de texte qui se chevauchent
    mergeRanges(matches) {
      if (!matches.length) return [];

      matches.sort((a, b) => a.start - b.start);
      const merged = [matches[0]];

      for (let i = 1; i < matches.length; i++) {
        const current = matches[i];
        const last = merged[merged.length - 1];

        if (current.start <= last.end) {
          // Chevauchement détecté, fusion des plages
          last.end = Math.max(last.end, current.end);
        } else {
          // Pas de chevauchement, ajouter comme nouvelle plage
          merged.push(current);
        }
      }

      return merged;
    },

    // Échapper les caractères spéciaux pour les expressions régulières
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },

    // Format text avec option de surlignage de termes clés
    formatText(text, withHighlighting = false) {
      if (!text) return '';

      // 1. Échapper le HTML
      let formattedText = this.escapeHTML(text);

      // 2. Mettre en italique les citations
      formattedText = formattedText.replace(/"([^"]+)"/g, '"<em>$1</em>"');
      formattedText = formattedText.replace(/«([^»]+)»/g, '«<em>$1</em>»');

      // 3. Traiter les références
      formattedText = this.processReferencesInText(formattedText);

      // 4. Convertir les titres markdown
      formattedText = formattedText.replace(
        /###\s+(.*?)(?:\n|$)/g,
        '<h1 style="margin-top: 1em; font-size:1.25em; font-weight:bold;">$1</h1>'
      );
      formattedText = formattedText.replace(
        /##\s+(.*?)(?:\n|$)/g,
        '<h3 style="margin-top: 1em; font-size:1.1em; font-weight:bold;">$1</h3>'
      );
      formattedText = formattedText.replace(
        /#\s+(.*?)(?:\n|$)/g,
        '<h2 style="margin-top: 1em; font-size:1.05em; font-weight:bold;">$1</h2>'
      );

      // 5. Remplacer les sauts de ligne
      formattedText = formattedText.replace(/\n/g, '<br>');

      // 6. Convertir la mise en forme markdown
      formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      formattedText = formattedText.replace(/\*(.*?)\*/g, '<em>$1</em>');
      formattedText = formattedText.replace(/_(.*?)_/g, '<u>$1</u>');

      // 7. Surligner les termes clés seulement si demandé
      if (withHighlighting && this.extractedKeywords.length > 0) {
        formattedText = this.highlightKeywords(formattedText, this.extractedKeywords);
      }

      return formattedText;
    },

    // Configuration de l'Intersection Observer pour optimiser le rendu des longs documents
    setupVirtualHighlighting() {
      // Ne s'applique que si nous avons beaucoup de contenu dans le panneau d'article
      const contentElement = document.querySelector('.article-details .details-content');
      if (!contentElement) return;

      // Extraire les termes clés
      const keywords = this.extractedKeywords;
      if (!keywords.length) return;

      // Créer un observer qui ne traitera que les sections visibles
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Section visible, appliquer le surlignage dynamiquement
            this.applyHighlightingToSection(entry.target, keywords);
            // Une fois traité, ne plus observer cette section
            observer.unobserve(entry.target);
          }
        });
      }, {
        rootMargin: '100px 0px', // Préchargement avec une marge de 100px
        threshold: 0.1 // Déclencher quand 10% de l'élément est visible
      });

      // Observer les éléments enfants du contenu
      const contentSections = contentElement.children;
      Array.from(contentSections).forEach(section => {
        observer.observe(section);
      });
    },

    // Appliquer le surlignage à une section spécifique
    applyHighlightingToSection(section, keywords) {
      // Ne pas retraiter les sections déjà surlignées
      if (section.dataset.highlighted === 'true') return;

      // Parcourir tous les nœuds de texte de la section
      const textNodes = [];
      const walker = document.createTreeWalker(section, NodeFilter.SHOW_TEXT);

      let node;
      while ((node = walker.nextNode())) {
        // Ignorer les nœuds vides ou dans des éléments déjà surlignés
        if (node.nodeValue.trim() === '' ||
            node.parentElement.classList.contains('keyword-highlight') ||
            node.parentElement.classList.contains('inline-reference')) {
          continue;
        }
        textNodes.push(node);
      }

      // Élargir l'ensemble de termes à rechercher
      const expandedKeywords = this.expandKeywords(keywords);

      // Surligner chaque occurrence dans les nœuds de texte
      textNodes.forEach(textNode => {
        let nodeContent = textNode.nodeValue;
        let hasMatch = false;
        console.log(hasMatch);
        expandedKeywords.forEach(keyword => {
          const regex = new RegExp(`\\b${this.escapeRegExp(keyword)}\\b`, 'gi');
          if (regex.test(nodeContent)) {
            hasMatch = true;
            // Remplacer le nœud texte par des éléments HTML avec surlignage
            const fragment = document.createDocumentFragment();
            let lastIndex = 0;

            // Reset regex pour commencer la recherche au début
            regex.lastIndex = 0;
            let match;
            while ((match = regex.exec(nodeContent)) !== null) {
              // Ajouter le texte avant la correspondance
              if (match.index > lastIndex) {
                fragment.appendChild(document.createTextNode(nodeContent.substring(lastIndex, match.index)));
              }

              // Ajouter la correspondance surlignée
              const span = document.createElement('span');
              span.className = 'keyword-highlight';
              span.textContent = match[0];
              fragment.appendChild(span);

              lastIndex = match.index + match[0].length;
            }

            // Ajouter le reste du texte
            if (lastIndex < nodeContent.length) {
              fragment.appendChild(document.createTextNode(nodeContent.substring(lastIndex)));
            }

            // Remplacer le nœud original par le fragment
            textNode.parentNode.replaceChild(fragment, textNode);
          }
        });
      });

      // Marquer la section comme traitée
      section.dataset.highlighted = 'true';
    },

    // Appliquer le surlignage uniquement au panneau des détails d'article
    applyKeywordHighlighting() {
      // Ne s'applique que si le panneau des détails est ouvert
      if (this.showArticlePanel) {
        this.$nextTick(() => {
          const detailsContent = document.querySelector('.article-details .details-content');
          if (detailsContent && this.extractedKeywords.length > 0) {
            // Si nous avons beaucoup de contenu, utiliser la virtualisation
            if (detailsContent.clientHeight > 2000) {
              this.setupVirtualHighlighting();
            } else {
              // Sinon appliquer directement
              this.applyHighlightingToSection(detailsContent, this.extractedKeywords);
            }
          }
        });
      }
    },

    // ========== Méthodes d'origine existantes ==========
    escapeHTML(text) {
      if (!text) return '';
      const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
      };
      return text.replace(/[&<>"']/g, m => map[m]);
    },

    processReferencesInText(text) {
      if (!text) return text;

      const expandedText = this.expandMultiReferences(text);
      const refRegex = /((?:ID:\s+)?(?:Jurisprudence|Legiarti|Bofip|Euritext)\s*-\s*\d+)/g;

      return expandedText.replace(refRegex, (match) => {
        const refMatch = match.match(/^(?:ID:\s+)?(Jurisprudence|Legiarti|Bofip|Euritext)\s*-\s*(\d+)$/);

        if (!refMatch) return match;

        const refType = refMatch[1];
        const refIndex = parseInt(refMatch[2], 10) - 1;

        let sourceKey = null;
        if (refType === 'Jurisprudence') sourceKey = 'juritext';
        else if (refType === 'Legiarti') sourceKey = 'legiarti';
        else if (refType === 'Bofip') sourceKey = 'bofip';
        else if (refType === 'Euritext') sourceKey = 'euritext';

        if (sourceKey && this.indexLookup[sourceKey] && this.indexLookup[sourceKey][refIndex]) {
          const doc = this.indexLookup[sourceKey][refIndex];
          const docTitle = doc.title || doc.textNumber || `${refType} #${refIndex + 1}`;

          return `<a href="#" class="inline-reference" data-source="${sourceKey}" data-index="${refIndex}">${docTitle}</a>`;
        }

        return `<span class="inactive-reference">${match}</span>`;
      });
    },

    expandMultiReferences(text) {
      if (!text) return '';

      const multiRefRegex = /((?:ID:\s+)?(?:Jurisprudence|Legiarti|Bofip|Euritext))(\s*(?:-\s*|\s+))(\d+(?:\s*,\s*\d+)*)/g;

      return text.replace(multiRefRegex, (match, refType, sep, numbersPart) => {
        const numbers = numbersPart.split(/\s*,\s*/);
        return numbers.map(num => `${refType} - ${num}`).join(', ');
      });
    },

    handleInlineReferenceClick(event) {
      event.preventDefault();

      const refElement = event.target.closest('.inline-reference');

      if (refElement) {
        const sourceKey = refElement.getAttribute('data-source');
        const refIndex = parseInt(refElement.getAttribute('data-index'), 10);

        if (sourceKey && !isNaN(refIndex) && this.indexLookup[sourceKey] && this.indexLookup[sourceKey][refIndex]) {
          const doc = this.indexLookup[sourceKey][refIndex];
          const details = this.processDocumentBySource(doc, sourceKey, refIndex);
          this.showArticleDetails(details);
        }
      }
    },

    processDocumentBySource(doc, sourceKey, index) {
      const details = {
        title: doc.title || doc.textNumber || `${this.getSourceTypeName(sourceKey)} #${index + 1}`,
        content: sourceKey === 'juritext' ? (doc.summary || '') : (doc.content || ''),
        source: sourceKey,
        id: doc.id,
        sourceData: doc
      };

      switch (sourceKey) {
        case 'juritext':
          details.texte = doc.texte || "";
          break;
        case 'legiarti':
          details.hierarchy = doc.hierarchy;
          details.annotation = doc.annotation || ""; // Add the annotation
          break;
        case 'bofip':
          details.url = doc.url;
          break;
        case 'euritext':
          if (doc.links) {
            details.links = doc.links;
          }
          break;
      }

      return details;
    },

    buildSourceIndexes() {
      if (!this.pointData || !this.pointData.analyses) return;

      const indexLookup = {
        juritext: {},
        legiarti: {},
        bofip: {},
        euritext: {}
      };

      this.pointData.analyses.forEach(sourceBlock => {
        const sourceKey = sourceBlock.source;
        if (!indexLookup[sourceKey]) return;

        sourceBlock.analysis.forEach((doc, index) => {
          indexLookup[sourceKey][index] = doc;
        });
      });

      this.indexLookup = indexLookup;
    },

    getSourceTitle(sourceType) {
      const titles = {
        'legiarti': 'Articles de loi',
        'juritext': 'Décisions de justice',
        'bofip': 'Doctrine administrative',
        'euritext': 'Jurisprudence européenne',
        'convbi': 'Conventions'
      };
      return titles[sourceType] || sourceType;
    },

    getSourceTypeName(sourceKey) {
      const names = {
        'legiarti': 'Article',
        'juritext': 'Décision',
        'bofip': 'Doctrine',
        'euritext': 'Jurisprudence'
      };
      return names[sourceKey] || 'Document';
    },

    getBofipSources() {
      return this.getSourceItems('bofip');
    },

    getEuritextSources() {
      return this.getSourceItems('euritext');
    },

    getJuritextSources() {
      return this.getSourceItems('juritext');
    },

    getLegiartiSources() {
      return this.getSourceItems('legiarti');
    },

    getSourceItems(sourceType) {
      if (!this.pointData || !this.pointData.analyses) return [];

      const sourceBlock = this.pointData.analyses.find(block => block.source === sourceType);
      return sourceBlock && sourceBlock.analysis ? sourceBlock.analysis : [];
    },

    toggleBodyScroll(enable) {
      if (enable) {
        document.body.classList.remove('modal-open');
      } else {
        document.body.classList.add('modal-open');
      }
    }
  }
};
</script>

<style>

.annotation-section {
  margin-top: 30px;
  padding: 15px;
  background-color: #f5f5ff;
  border-left: 4px solid #625CFB;
  border-radius: 0 6px 6px 0;
}

.annotation-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: #625CFB;
  font-weight: bold;
}

.annotation-content {
  line-height: 1.6;
  font-size: 14px;
}

/* Add a subtle separator between the article and annotation */
.annotation-section::before {
  content: "";
  display: block;
  height: 1px;
  background: linear-gradient(to right, #625CFB, transparent);
  margin-bottom: 15px;
}

/* Styles globaux (non-scoped) */
.inline-reference {
  color: #625CFB !important;
  font-style: italic !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  transition: color 0.2s ease, background-color 0.2s ease !important;
  padding: 0 2px !important;
  border-radius: 2px !important;
  font-size: 0.9em !important;
}

.inline-reference:hover {
  color: #4338ca !important;
  background-color: rgba(99, 102, 241, 0.1) !important;
}

.inactive-reference {
  color: #6b7280 !important;
  font-style: italic !important;
  text-decoration: underline dotted !important;
}

/* Style pour le surlignage des termes clés */
.keyword-highlight {
  background-color: rgba(98, 92, 251, 0.2); /* Couleur violette transparente */
  color: #625CFB; /* Texte en violet */
  border-radius: 2px;
  padding: 0 2px;
  font-weight: 600;
  display: inline;
  position: relative;
}

/* Animation subtile au survol */
.keyword-highlight:hover {
  background-color: rgba(98, 92, 251, 0.3);
  transition: background-color 0.2s ease;
}

/* Bordure inférieure pour accentuer le surlignage */
.keyword-highlight::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #625CFB;
  opacity: 0.6;
}

/* Style pour fixed body */
.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}
</style>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
}

.modal-content {
  width: 90%;
  height: 90vh;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-header {
  background-color: #f2f2f2;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  flex-shrink: 0;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-left {
  width: 20%;
}

.tabs {
  display: flex;
  gap: 10px;
  justify-content: center;
  background-color: #e3e3e4;
  border-radius: 6px;
  width: 60%;
}

.header-right {
  width: 20%;
  text-align: right;
}

.tab-button {
  flex: 1;
  background: none;
  border: none;
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;
  color: #333;
  background-color: #e3e3e4;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: #FFF;
  font-weight: bold;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 2px;
}

.tab-button:hover:not(.active) {
  background-color: #d1d1d2;
}

.close-button, .close-details {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.tab-content {
  display: flex;
  flex-direction: column;
  padding: 3em;
  height: calc(100% - 60px);
  overflow: hidden;
}

.content-title {
  margin-top: 0;
  margin-bottom: 2em;
  font-size: 1.3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-content-container {
  display: flex;
  height: calc(100% - 50px);
  gap: 20px;
  overflow: hidden;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  font-size: 14px;
  line-height: 1.6;
}

.article-content {
  line-height: 1.6;
}

.article-link {
  color: #625CFB;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 10px;
}

.source-block {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
}

.source-items {
  margin-top: 15px;
}

.source-item {
  margin-bottom: 10px;
}

.article-details {
  width: 60%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #DFDFDF;
}

.bg-blue-color {
  background-color: #f2f2ff;
}

.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px;
  border-bottom: 1px solid #eaeaea;
}

.details-header h3 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.details-content {
  padding: 20px;
  overflow-y: auto;
  font-size: 14px;
}

.hierarchy {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
  color: #625CFB;
  font-size: 0.95em;
}

.decision-meta {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
}

.full-decision {
  margin-top: 20px;
}

.full-decision a {
  color: #625CFB;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
}

.source-url {
  margin-bottom: 20px;
  border-radius: 5px;
}

.source-url a {
  color: #625CFB;
  text-decoration: none;
}

.euritext-links {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f4f8;
  border-radius: 5px;
}

.euritext-link {
  margin-top: 8px;
}

.euritext-link a {
  color: #625CFB;
  text-decoration: none;
}

.empty-message {
  color: #888;
  font-style: italic;
  text-align: center;
  margin-top: 20px;
}

.highlight {
  color: #625CFB;
  text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    height: 95vh;
  }

  .main-content-container {
    flex-direction: column;
  }

  .main-content {
    max-height: 50%;
  }

  .article-details {
    width: 100%;
    max-height: 50%;
  }

  .tabs {
    width: 100%;
  }

  .tab-button {
    padding: 8px;
    font-size: 11px;
  }

  .header-left, .header-right {
    display: none;
  }
}
</style>
