import html2pdf from 'html2pdf.js';

class PdfGenerator {
  /**
   * Generate a PDF from dossier data
   * @param {Object} dossierData - The dossier data object
   * @param {Array} analysesFiscales - Array of analysis points
   * @param {Function} formatTextFn - The text formatting function
   * @returns {Promise} - Promise that resolves when the PDF is generated
   */
  static async generateDossierPDF(dossierData, analysesFiscales, formatTextFn) {
    let container = null;

    try {
      // Create the HTML content
      const content = this._createPdfContent(dossierData, analysesFiscales, formatTextFn);

      // Define PDF options with adjusted settings for better compatibility
      const options = {
        margin: [15, 0, 15, 0], // Top, Right, Bottom, Left
        filename: `${this._sanitizeFilename(dossierData.name || 'Dossier')}_${new Date().toISOString().slice(0, 10)}.pdf`,
        image: { type: 'jpeg', quality: 0.9 },
        html2canvas: {
          scale: 5,
          useCORS: true,
          logging: true, // Enable logging for debugging
          letterRendering: true,
          allowTaint: true,
          removeContainer: true,
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
          compress: true
        },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], before: '.page-break-before', after: '.page-break-after' }
      };

      // Create temporary container with explicit dimensions and visibility
      container = document.createElement('div');
      container.className = 'pdf-temp-container';
      container.innerHTML = content;

      // Critical for rendering: make the container visible but out of view
      document.body.appendChild(container);

      // IMPORTANT: Instead of hiding with negative positioning (which can cause rendering issues),
      // use opacity and position it far down the page where it won't be visible
      // container.style.position = 'absolute';
      // container.style.width = '210mm'; // A4 width at 96 DPI
      container.style.backgroundColor = 'white';
      //container.style.zIndex = '-9999'; // Behind everything else

      // Wait for any images or resources to load before generating the PDF
      return new Promise((resolve, reject) => {
        // Add a small delay to ensure the DOM has time to render
        setTimeout(() => {
          console.log('Container before PDF generation:', container);

          // Check if container has content
          if (!container.innerHTML || container.innerHTML.trim() === '') {
            if (container.parentNode) {
              document.body.removeChild(container);
            }
            reject(new Error('Container is empty before PDF generation'));
            return;
          }

          // Use html2pdf directly without storing the worker reference
          html2pdf()
            .from(container)
            .set(options)
            .save()
            .then(() => {
              console.log('PDF generated successfully');
              // Clean up
              if (container.parentNode) {
                document.body.removeChild(container);
              }
              resolve(true);
            })
            .catch(err => {
              console.error('PDF generation error:', err);
              // Clean up
              if (container.parentNode) {
                document.body.removeChild(container);
              }
              reject(err);
            });
        }, 15000); // 5000ms delay to allow DOM rendering
      });
    } catch (error) {
      // Clean up
      if (container && container.parentNode) {
        document.body.removeChild(container);
      }
      console.error('Error in PDF generation setup:', error);
      throw error;
    }
  }

  /**
   * Create the HTML content for the PDF with additional inline styles
   * @private
   */
  static _createPdfContent(dossierData, analysesFiscales, formatTextFn) {
    console.log('Creating PDF content with dossier data:');
    const title = dossierData.contexte || "Recherche juridique";
    const currentDate = new Date().toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    // Format the legal framework section
    const synthese = dossierData.synthese ? formatTextFn(dossierData.synthese, true, analysesFiscales) : "";

    // Build the analyses section
    let analysesHTML = '';
    if (analysesFiscales && analysesFiscales.length > 0) {
      analysesFiscales.forEach((point, index) => {
        // For each analysis point, pass the entire analysesFiscales array
        const formattedAnswer = point.final_answer ? formatTextFn(point.final_answer, true, analysesFiscales) : "";
        analysesHTML += `
          <div class="pdf-analysis-point ${index > 0 ? 'page-break-before' : ''}" style="margin-bottom: 20px;">
            <h1 style="font-size: 20px; color: #6c63ff; margin-bottom: 18px;">${this._escapeHtml(point.point)}</h1>
            <div class="pdf-analysis-content" style="font-size: 14px; text-align: justify;">
              ${formattedAnswer}
            </div>
          </div>
        `;
      });
    } else {
      analysesHTML = '<p class="pdf-empty-message" style="font-style: italic; color: #888; text-align: center;">Aucun point d\'analyse disponible</p>';
    }

    // Complete PDF template with INLINE styles (critical for proper rendering)
    return `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="UTF-8">
        <title>Dossier juridique</title>
        <style>
          /* PDF Styles */
          body {
            font-family: 'Helvetica', 'Arial', sans-serif !important;
            color: #333;
            line-height: 1.5;
            margin-bottom: 0;
            padding: 0;
            width: 210mm;
          }

          .pdf-container {
            width: 210mm;
            margin: 0 auto;
            padding: 15mm;
            box-sizing: border-box;
            background-color: white;
          }

          .pdf-header {
            text-align: center;
            margin-bottom: 30px;
            padding-bottom: 15px;
            border-bottom: 2px solid #6c63ff;
          }

          .pdf-title {
            font-size: 24px;
            color: #6c63ff;
            margin-bottom: 5px;
          }

          .pdf-date {
            font-size: 14px;
            color: #666;
          }

          .pdf-section {
            margin-bottom: 25px;
          }

          .pdf-section-title {
            font-size: 18px;
            color: #6c63ff;
            padding-bottom: 5px;
            margin-bottom: 15px;
          }

          .pdf-context {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 20px;
          }

          .pdf-legal-framework,
          .pdf-analysis-content {
            font-size: 14px;
            text-align: justify;
          }

          .pdf-analysis-content,
          .pdf-context,
          .pdf-legal-framework {
            overflow-wrap: break-word;
            word-break: break-all; /* Use with caution: may break words abruptly */
          }

          .pdf-analysis-point {
            margin-bottom: 20px;
          }

          .pdf-analysis-point h3 {
            font-size: 16px;
            color: #333;
            margin-bottom: 10px;
          }

          .pdf-empty-message {
            font-style: italic;
            color: #888;
            text-align: center;
          }

          .pdf-footer {
            margin-top: 30px;
            padding-top: 15px;
            border-top: 1px solid #e0e0e0;
            font-size: 12px;
            color: #888;
            text-align: center;
          }

          .page-break-before {
            page-break-before: always;
          }

          .page-break-after {
            page-break-after: always;
          }

          h1, h2, h3, h4, h5, h6 {
            font-family: 'Helvetica', 'Arial', sans-serif !important;
          }

          p, div, span {
            font-family: 'Helvetica', 'Arial', sans-serif !important;
          }
        </style>
      </head>
      <body>
        <div class="pdf-container" style="width: 210mm; margin: 0 auto; padding: 15mm; box-sizing: border-box; background-color: white;">
          <div class="pdf-header" style="text-align: center; margin-bottom: 30px; padding-bottom: 15px; border-bottom: 2px solid #6c63ff;">
            <h1 class="pdf-title" style="font-size: 24px; color: #6c63ff; margin-bottom: 5px; font-family: 'Helvetica', 'Arial', sans-serif;">Synthèse Juridique</h1>
            <p class="pdf-date" style="font-size: 14px; color: #666; font-family: 'Helvetica', 'Arial', sans-serif;">${currentDate}</p>
          </div>

          <div class="pdf-section" style="margin-bottom: 25px;">
            <p class="pdf-context" style="font-weight: bold; font-size: 16px; margin-bottom: 20px; font-family: 'Helvetica', 'Arial', sans-serif;">${this._escapeHtml(title)}</p>
          </div>

          <div class="pdf-section" style="margin-bottom: 25px;">
            <h2 class="pdf-section-title" style="font-size: 18px; color: #6c63ff; padding-bottom: 5px; margin-bottom: 15px; font-family: 'Helvetica', 'Arial', sans-serif;">Présentation & Introduction aux résultats de la recherches</h2>
            <div class="pdf-legal-framework page-break-after" style="font-size: 14px; text-align: justify; page-break-after: always; font-family: 'Helvetica', 'Arial', sans-serif;">
              ${synthese}
            </div>
          </div>

          <div class="pdf-section" style="margin-bottom: 25px;">
            <h2 class="pdf-section-title" style="font-size: 18px; color: #6c63ff; padding-bottom: 5px; margin-bottom: 15px; font-family: 'Helvetica', 'Arial', sans-serif;">Analyses détaillées</h2>
            <div class="pdf-analyses" style="font-family: 'Helvetica', 'Arial', sans-serif;">
              ${analysesHTML}
            </div>
          </div>

          <div class="pdf-footer" style="margin-top: 30px; padding-top: 15px; border-top: 1px solid #e0e0e0; font-size: 12px; color: #888; text-align: center; font-family: 'Helvetica', 'Arial', sans-serif;">
            <p style="font-family: 'Helvetica', 'Arial', sans-serif;">Document généré par votre agent Query Juriste</p>
            <p style="font-family: 'Helvetica', 'Arial', sans-serif;">${currentDate}</p>
          </div>
        </div>
      </body>
      </html>
    `;
  }

  /**
   * Sanitize filename to remove invalid characters
   * @private
   */
  static _sanitizeFilename(filename) {
    return filename
      .replace(/[/\\:*?"<>|]/g, '_')
      .replace(/\s+/g, '_')
      .slice(0, 50); // Limit filename length
  }

  /**
   * Escape HTML special characters to prevent XSS in the PDF
   * @private
   */
  static _escapeHtml(text) {
    if (!text) return '';
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };
    return text.replace(/[&<>"']/g, m => map[m]);
  }
}

export default PdfGenerator;
