/**
 * Service for formatting text in a consistent manner
 * This handles markdown-like syntax for PDF and HTML display
 */
class TextFormatter {
  /**
   * Format text with markdown-like syntax for PDF or HTML
   * @param {string} text - The input text to format
   * @param {boolean} forPdf - Whether formatting is for PDF (true) or HTML (false)
   * @returns {string} - Formatted text
   */
  static format(text, forPdf = true, analysesFiscales = null) {
    console.log('TextFormatter.js');
    if (!text) return '';
    console.log('TextFormatter.js');

    // Escape HTML to prevent XSS attacks
    let formattedText = TextFormatter.escapeHtml(text);

    // Convert line breaks to <br> tags
    formattedText = formattedText.replace(/\n/g, '<br>');

    // Format citations
    formattedText = formattedText.replace(/"([^"]+)"/g, '"<em>$1</em>"');
    formattedText = formattedText.replace(/«([^»]+)»/g, '«<em>$1</em>»');

    // Process headers with appropriate styling
    if (forPdf) {
      // For PDF, we want slightly different styling
      formattedText = formattedText.replace(
        /###\s+(.*?)(?:<br>|$)/g,
        '<h3 style="font-weight: bold; font-size: 1.1em; margin-top: 0.8em; margin-bottom: 0.4em; color: #444;">$1</h3>'
      );
      formattedText = formattedText.replace(
        /##\s+(.*?)(?:<br>|$)/g,
        '<h2 style="font-weight: bold; font-size: 1.3em; margin-top: 1em; margin-bottom: 0.5em; color: #333;">$1</h2>'
      );
      // For PDF (triple ###):
      formattedText = formattedText.replace(
        /###(?:<br>|\s)+(.*?)(?:<br>|$)/g,
        '<h3 style="font-weight: bold; font-size: 1.5em; margin-top: 0.8em; margin-bottom: 0.4em; color: #444;">$1</h3>'
      );

    } else {
      // For HTML in the application
      // For PDF (triple ###):
      formattedText = formattedText.replace(
        /###(?:<br>|\s)+(.*?)(?:<br>|$)/g,
        '<h3 style="font-weight: bold; font-size: 1em; margin-top: 1em; margin-bottom: 0.4em; color: #444;">$1</h3>'
      );

      formattedText = formattedText.replace(
        /##\s+(.*?)(?:<br>|$)/g,
        '<h2 style="font-weight: bold; margin-top: 1em; font-size:1.1em; font-weight:bold;">$1</h2>'
      );
      formattedText = formattedText.replace(
        /#\s+(.*?)(?:<br>|$)/g,
        '<h1 style="font-weight: bold; margin-top: 1em; font-size:1.25em; font-weight:bold;">$1</h1>'
      );
    }

    // Convert markdown formatting
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    formattedText = formattedText.replace(/\*(.*?)\*/g, '<em>$1</em>');
    formattedText = formattedText.replace(/_(.*?)_/g, '<u>$1</u>');

    // Handle lists - unordered
    formattedText = formattedText.replace(/^\s*-\s+(.*?)(?:<br>|$)/gm,
      '<li style="margin-bottom: 0.3em;">$1</li>');

    // Handle lists - ordered
    formattedText = formattedText.replace(/^\s*(\d+)\.\s+(.*?)(?:<br>|$)/gm,
      '<li style="margin-bottom: 0.3em;">$2</li>');

    // Wrap consecutive li elements in ul or ol
    formattedText = TextFormatter.wrapListItems(formattedText);

    // For PDF, ensure all hyperlinks open in a new tab and have visible URLs
    if (forPdf) {
      formattedText = TextFormatter.convertReferencesToFootnotes(formattedText, analysesFiscales);
    }

    // Special handling for PDF references - convert to footnotes or endnotes
    if (forPdf) {
      formattedText = TextFormatter.convertReferencesToFootnotes(formattedText);
    }

    console.log(formattedText);

    return formattedText;
  }

  /**
   * Wrap consecutive list items in appropriate list containers
   * @private
   */
  static wrapListItems(text) {
    // First, check if there are any list items
    if (!text.includes('<li')) return text;

    // Find consecutive li elements and wrap them
    let inList = false;
    let listType = '';
    const lines = text.split('<br>');
    const result = [];

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      if (line.includes('<li') && !inList) {
        // Start of a new list
        inList = true;
        listType = line.match(/^\s*\d+\./) ? 'ol' : 'ul';
        result.push(`<${listType} style="margin-top: 0.5em; margin-bottom: 0.8em; padding-left: 1.5em;">`);
        result.push(line);
      } else if (line.includes('<li') && inList) {
        // Continuation of the list
        result.push(line);
      } else if (!line.includes('<li') && inList) {
        // End of the list
        inList = false;
        result.push(`</${listType}>`);
        result.push(line);
      } else {
        // Regular line
        result.push(line);
      }
    }

    // Close any open list at the end
    if (inList) {
      result.push(`</${listType}>`);
    }

    return result.join('<br>');
  }

  /**
   * Convert inline references to footnotes or endnotes for PDF
   * @private
   */
  static convertReferencesToFootnotes(text, analysesFiscales) {
    if (!text || !analysesFiscales) return text;

    // Build document lookup similar to the modal component
    const indexLookup = this.buildDocumentLookup(analysesFiscales);

    // First, expand any multi-references (like "Jurisprudence-1,2,3")
    const expandedText = this.expandMultiReferences(text);

    // Match reference patterns
    const refRegex = /((?:ID:\s+)?(?:Jurisprudence|Legiarti|Bofip|Euritext)\s*-\s*\d+)/g;

    return expandedText.replace(refRegex, (match) => {
      const refMatch = match.match(/^(?:ID:\s+)?(Jurisprudence|Legiarti|Bofip|Euritext)\s*-\s*(\d+)$/);

      if (!refMatch) return match;

      const refType = refMatch[1];
      const refIndex = parseInt(refMatch[2], 10) - 1; // Convert to zero-based index

      // Map reference types to source keys
      let sourceKey = null;
      if (refType === 'Jurisprudence') sourceKey = 'juritext';
      else if (refType === 'Legiarti') sourceKey = 'legiarti';
      else if (refType === 'Bofip') sourceKey = 'bofip';
      else if (refType === 'Euritext') sourceKey = 'euritext';

      // Get document title from lookup if available
      if (sourceKey && indexLookup[sourceKey] && indexLookup[sourceKey][refIndex]) {
        const doc = indexLookup[sourceKey][refIndex];
        const docTitle = this.getDocumentTitle(doc, sourceKey, refIndex);

        // For PDF, format the reference with the actual document title
        return `<em style="color: #6c63ff; font-size: 0.8em;">${docTitle}</em>`;
      }

      // Fallback when document not found
      let sourceTypeName = refType;
      if (refType === 'Jurisprudence') sourceTypeName = 'Décision de justice';
      else if (refType === 'Legiarti') sourceTypeName = 'Article de loi';
      else if (refType === 'Bofip') sourceTypeName = 'Doctrine administrative';
      else if (refType === 'Euritext') sourceTypeName = 'Jurisprudence européenne';

      return `<em>${sourceTypeName} n°${refIndex + 1}</em>`;
    });
  }

  /**
   * Build a lookup object for documents from all analysis points
   * Similar to buildSourceIndexes in LegalAnalysisModal
   * @private
   */
  static buildDocumentLookup(analysesFiscales) {
    if (!analysesFiscales || !Array.isArray(analysesFiscales)) return {};

    const indexLookup = {
      juritext: {},
      legiarti: {},
      bofip: {},
      euritext: {}
    };

    // Go through each analysis point
    analysesFiscales.forEach(pointItem => {
      // Check if pointItem has analyses array
      if (!pointItem.analyses || !Array.isArray(pointItem.analyses)) return;

      // Go through each source block in the analyses
      pointItem.analyses.forEach(sourceBlock => {
        if (!sourceBlock.source || !sourceBlock.analysis) return;

        const sourceKey = sourceBlock.source;
        if (!indexLookup[sourceKey]) return;

        // Add each document to the lookup
        sourceBlock.analysis.forEach((doc, index) => {
          indexLookup[sourceKey][index] = doc;
        });
      });
    });

    return indexLookup;
  }

  /**
   * Get a display title for a document, similar to getDocumentTitle in DossierPage
   * @private
   */
  static getDocumentTitle(doc, sourceKey, index) {
    if (doc.title) return doc.title;

    switch (sourceKey) {
      case 'legiarti':
        return doc.textNumber || `Article #${index + 1}`;
      case 'juritext':
        return `Décision de justice #${index + 1}`;
      case 'bofip':
        return `Doctrine administrative #${index + 1}`;
      case 'euritext':
        return `Jurisprudence européenne #${index + 1}`;
      default:
        return `Document #${index + 1}`;
    }
  }

  /**
   * Helper method to expand multi-references into individual references
   * @private
   */
  static expandMultiReferences(text) {
    if (!text) return '';

    const multiRefRegex = /((?:ID:\s+)?(?:Jurisprudence|Legiarti|Bofip|Euritext))(\s*(?:-\s*|\s+))(\d+(?:\s*,\s*\d+)*)/g;

    return text.replace(multiRefRegex, (match, refType, sep, numbersPart) => {
      const numbers = numbersPart.split(/\s*,\s*/);
      return numbers.map(num => `${refType} - ${num}`).join(', ');
    });
  }

  /**
   * Escape HTML special characters
   * @private
   */
  static escapeHtml(text) {
    if (!text) return '';
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };
    return text.replace(/[&<>"']/g, m => map[m]);
  }
}

export default TextFormatter;
