<template>
  <div>
    <div class="content">
      <!-- Subscription and Delete Confirmation Modals -->
      <subscription-modal
        :visible="!isSubscribed && showModal"
        :products="products"
        @requestCloseModal="closeModal"
      ></subscription-modal>

      <DeleteConfirmationModal
        v-if="showDeleteModal"
        :dossierName="dossierToDelete?.name"
        @confirm="confirmDeleteDossier"
        @cancel="cancelDeleteDossier"
      />

      <div class="search-section text-center">
        <h2 class="search-title">Que recherchez-vous <span class="highlight">aujourd'hui ?</span></h2>
        <div class="main-section">
          <div class="search-bar mx-auto mt-4">
            <div class="input-container">
              <textarea
                v-model="searchQuery"
                class="form-control search-input"
                placeholder="Entrez votre nouvelle requête... Mot-clés, question..."
                rows="1"
                ref="searchBox"
                @input="adjustTextareaHeight"
                @keyup.enter="handleSearchSubmit"
              ></textarea>
            </div>
            <div class="buttons-line" v-if="!isSearchActive">
              <button
                class="btn button-base search-button tech-purple"
                style="font-size: 12px;"
                @click="handleSearchSubmit"
              >
                Rechercher
                <img src="@/assets/search.svg" alt="Search Icon" class="search-icon">
              </button>
            </div>
          </div>
          <button @click="goToExamplePage" class="mt-3 btn button-base outline-button" v-if="!isSearchActive">
              Guide d'utilisation
              <img src="@/assets/arrow-right.svg" alt="Search Icon" class="search-icon">
          </button>

          <!-- Conditional rendering: Show either recherches précédentes or the synthese content -->
          <!-- Show spinner while loading -->
          <div v-if="isLoadingDossiers" class="spinner-container my-4">
            <div class="spinner"></div>
          </div>
          <div v-if="!isSearchActive && dossiers.length > 0" class="researchs mt-4">
            <h6>Recherches précédentes</h6>

            <!-- Display dossiers -->
            <div class="mt-4 row">
              <div class="col-md-4 mb-3" v-for="(dossier, index) in displayedDossiers" :key="dossier.id">
                <div class="card p-3" @click="openDossier(dossier, index)">
                  <div class="text-right">
                    <img
                      src="@/assets/card-more.svg"
                      alt="More Options"
                      class="more-icon"
                      @click.stop="deleteDossier(dossier)"
                    >
                  </div>
                  <p class="mt-2 text-left">{{ dossier.name || 'Nouveau dossier' }}</p>
                </div>
              </div>
            </div>

            <!-- Toggle button for more dossiers -->
            <div class="text-center mt-3" style="justify-self: center;">
              <button
                v-if="dossiers.length > 6"
                @click="toggleShowAllDossiers"
                class="btn button-base outline-button"
              >
                {{ showAllDossiers ? 'Voir moins de recherches' : 'Voir plus de recherches' }}
              </button>
            </div>
          </div>

          <!-- Integrated SyntheseModal content (replaces the recherches section) -->
          <div v-else-if="isSearchActive" class="synthese-container mt-4">
            <!-- Left Content from SyntheseModal -->
            <div class="synthese-content">
              <div v-if="isLoadingPoints" class="loading-message">
                <!-- Show loading message or spinner -->
                <div class="title-points">
                  <h4 style="font-size: 1.2rem; font-weight: 300">
                    <em>Identification des points de droits...</em>
                  </h4>
                  <div class="spinner ml-3"></div>
                </div>
              </div>

              <div v-else-if="pointsList.length === 0">
                <!-- Show the button to fetch points -->
                <div class="buttons">
                  <button @click="fetchPoints" class="enrichir-button">
                    Lancer la recherche
                  </button>
                  <button @click="cancelSearch" class="back-button">Annuler</button>
                </div>
              </div>

              <transition name="fade">
                <div v-if="pointsList.length !== 0" class="points-class">
                  <!-- Display the points with editing capabilities -->
                  <div class="title-points">
                    <h4 style="font-size: 1.2rem; font-weight: 300">
                      <em>
                        Notre système effectuera une recherche sur chacun de ces
                        points
                      </em>
                    </h4>
                  </div>
                  <ul class="points-list">
                    <li
                      v-for="(point, index) in pointsList"
                      :key="point.id"
                      class="point-item"
                    >
                      <!-- Point Display and Editing -->
                      <div v-if="point.isEditing" class="point-display">
                        <textarea
                          v-focus="point.isEditing"
                          v-model="point.text"
                          rows="2"
                          cols="50"
                          class="point-input bg-light"
                        ></textarea>
                        <button @click="savePoint(index)" class="small-button">
                          <!-- Save icon SVG -->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-check-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                            />
                            <path
                              d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"
                            />
                          </svg>
                        </button>
                      </div>
                      <div v-else class="point-display">
                        <div class="point-text bg-light">
                          <div style="margin-right: 1em; width: 74%;">
                            {{ point.text }}
                          </div>
                          <button @click="editPoint(index)" class="small-button">
                            <img src="@/assets/button-modify-point.svg">
                          </button>
                          <button @click="deletePoint(index)" class="small-button">
                            <img src="@/assets/button-delete-point.svg">
                          </button>
                        </div>

                      </div>

                      <!-- Sources Associated with the Point -->
                      <ul class="sources-list" style = "display: none">
                        <li
                          style="font-size: 0.7rem; font-weight: 300; margin-top: 4px"
                        >
                          Sources:
                        </li>
                        <li
                          v-for="(source, sIndex) in point.sources"
                          :key="sIndex"
                          class="source-item"
                        >
                          {{ source }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <!-- Buttons to start analysis or cancel -->
                  <div class="buttons">
                    <button @click="cancelSearch" class="back-button">
                      Annuler
                    </button>
                    <button
                      @click="startAnalysis"
                      class="enrichir-button"
                      :disabled="pointsList.length === 0"
                      :class="{ 'button-disabled': pointsList.length === 0 }"
                    >
                      Démarrer la recherche
                    </button>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionModal from './SubscriptionModal.vue';
import DeleteConfirmationModal from './DeleteConfirmationModal.vue';
import { auth } from "@/firebase/firebaseConfig";
// import { logout } from '@/firebase/auth';
import { db } from "@/firebase/firebaseConfig";
import { collection, query, where, doc, getDocs, deleteDoc, addDoc, serverTimestamp, orderBy, updateDoc } from 'firebase/firestore';
import axios from "axios";
import { getFirebaseToken } from "@/services/api";

export default {
  name: 'DashboardPage',

  components: {
    SubscriptionModal,
    DeleteConfirmationModal
  },

  data() {
    return {
      // Dossier related data
      dossiers: [],
      showAllDossiers: false,
      isLoadingDossiers: true,
      showDeleteModal: false,
      dossierToDelete: null,

      // Search and analysis related data
      searchQuery: '',
      isSearchActive: false,

      // Points related data (from SyntheseModal)
      pointsList: [],
      isLoadingPoints: false,
      pointIdCounter: 0,

      // Subscription related data
      products: [],
      isSubscribed: false,
      showModal: false,
      isLoading: false,
      trialing: false,

      // Unsubscribe function for snapshot listener
      unsubscribe: null
    };
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },

  computed: {
    // Determines which dossiers to display based on showAllDossiers
    displayedDossiers() {
      if (this.showAllDossiers) {
        return this.dossiers;
      }
      return this.dossiers.slice(0, 6); // Show 6 dossiers by default
    },
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.fetchSubscriptionStatus();
    this.fetchUserDossiers();

    this.$nextTick(() => {
      if (this.$refs.searchBox) {
        this.adjustTextareaHeight();
      }
    });
  },

  methods: {
    // Handle textarea auto-resize
    adjustTextareaHeight() {
      const textarea = this.$refs.searchBox;
      if (!textarea) return;

      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 300)}px`; // Max height of 300px
    },

    // Handle search submission
    handleSearchSubmit() {
      if (!this.searchQuery.trim()) {
        alert("Veuillez entrer une requête de recherche.");
        return;
      }

      // Instead of showing modal, activate the search interface
      this.isSearchActive = true;
      // Auto-fetch points when search is activated
      this.fetchPoints();
    },

    // Cancel the search and return to previous searches view
    cancelSearch() {
      this.isSearchActive = false;
      this.pointsList = [];
      this.isLoadingPoints = false;
    },

    // Fetch user dossiers from Firebase
    async fetchUserDossiers() {
      this.isLoadingDossiers = true;
      try {
        const userId = auth.currentUser.uid;
        const dossiersRef = collection(db, 'users', userId, 'dossiers');

        // Fetch dossiers sorted by 'createdAt' in descending order
        const q = query(dossiersRef, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);

        // Map the fetched documents to the dossiers array
        this.dossiers = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching dossiers:', error);
      } finally {
        // Stop loading spinner
        this.isLoadingDossiers = false;
      }
    },

    // Toggle the display of all dossiers
    toggleShowAllDossiers() {
      this.showAllDossiers = !this.showAllDossiers;
    },

    // Open a specific dossier
    openDossier(dossier, index) {
      console.log('Opening dossier:', index);
      this.$router.push({ name: 'DossierPage', params: { dossierId: dossier.id } });
    },

    // Delete a dossier
    deleteDossier(dossier) {
      this.dossierToDelete = dossier;
      this.showDeleteModal = true;
    },

    // Confirm deletion of a dossier
    async confirmDeleteDossier() {
      if (!this.dossierToDelete) return;
      try {
        const userId = auth.currentUser.uid;
        const dossierId = this.dossierToDelete.id;

        // Delete all cases within the dossier
        const casesRef = collection(db, 'users', userId, 'dossiers', dossierId, 'cases');
        const casesSnapshot = await getDocs(casesRef);
        const deleteCasePromises = casesSnapshot.docs.map(doc => deleteDoc(doc.ref));
        await Promise.all(deleteCasePromises);

        // Delete the dossier document
        const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);
        await deleteDoc(dossierDocRef);

        // Remove the dossier from the local array
        this.dossiers = this.dossiers.filter(d => d.id !== dossierId);

        // Reset the modal and dossierToDelete
        this.dossierToDelete = null;
        this.showDeleteModal = false;

      } catch (error) {
        console.error('Error deleting dossier:', error);
        alert('Erreur lors de la suppression du dossier.');
        // Reset the modal and dossierToDelete even if there's an error
        this.dossierToDelete = null;
        this.showDeleteModal = false;
      }
    },

    // Cancel deletion
    cancelDeleteDossier() {
      this.dossierToDelete = null;
      this.showDeleteModal = false;
    },

    // Navigate to the Example Page
    goToExamplePage() {
      this.$router.push({ name: 'example' });
    },

    // Methods from SyntheseModal
    fetchPoints() {
      if (!this.searchQuery.trim()) {
        alert('Le contexte est vide.');
        return;
      }
      this.isLoadingPoints = true;
      getFirebaseToken().then(token => {
        if (!token) {
          alert('Erreur d\'authentification. Veuillez réessayer.');
          this.isLoadingPoints = false;
          return;
        }

        axios.post(`https://api.query-juriste.com/copilot/get_points_new`, {
          contexte: this.searchQuery,
          userId: auth.currentUser.uid,
          dossierId: this.$route.params.dossierId,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          // Map the received points to include isEditing property
          this.pointsList = (response.data.points || []).map(point => ({
            text: point.problem,
            originalText: point.problem,
            sources: point.source.split(',').map(s => s.trim()),
            isEditing: false,
            id: this.pointIdCounter++,
          }));
          this.isLoadingPoints = false;

          // Scroll to bottom after points are loaded
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }).catch(error => {
          console.error('Error fetching points:', error);
          alert('Erreur lors de la récupération des points.');
          this.isLoadingPoints = false;
        });

      }).catch(error => {
        console.error('Error fetching token:', error);
        alert('Erreur lors de la récupération du token. Veuillez réessayer.');
        this.isLoadingPoints = false;
      });
    },

    deletePoint(index) {
      this.pointsList.splice(index, 1);
    },

    savePoint(index) {
      const point = this.pointsList[index];
      if (point.text.trim() === '') {
        alert('Le point ne peut pas être vide.');
        return;
      }
      point.isEditing = false;
    },

    startAnalysis() {
      if (this.pointsList.length === 0) {
        alert('Aucun point n\'a été sélectionné.');
        return;
      }

      // Start analysis with current context and points list
      this.startActualAnalysis(this.searchQuery, this.pointsList);

      // Reset the search interface
      this.isSearchActive = false;
      this.pointsList = [];
    },

    // This method is the actual startAnalysis from the original component
    async startActualAnalysis(context, pointList) {

      if (!context.trim()) {
        alert("Veuillez fournir un contexte pour la recherche.");
        return;
      }

      // First, create a new dossier
      try {
        const userId = auth.currentUser.uid;
        const dossiersRef = collection(db, 'users', userId, 'dossiers');

        // Generate a dossier name from the context (truncate if too long)
        const dossierName = context.length > 30 ?
            context.substring(0, 30) + '...' :
            context;

        // Create new dossier with the search query as its name
        const newDossier = {
          name: dossierName,
          contexte: context,
          createdAt: serverTimestamp(),
          isAnalysing: "pending" // Set the analysis status to pending right away
        };

        // Add the dossier to Firestore
        const docRef = await addDoc(dossiersRef, newDossier);
        const dossierId = docRef.id;

        // Navigate to DossierPage with the newly created dossier ID
        this.$router.push({
          name: 'DossierPage',
          params: {
            dossierId: dossierId
          }
        });

        // Now initiate the analysis process via API
        getFirebaseToken()
          .then(token => {
            if (!token) {
              alert("Erreur d'authentification. Veuillez réessayer.");
              return;
            }

            axios.post(
              `https://api.query-juriste.com/copilot/get_analyse_fiscale`,
              {
                contexte: context,
                dossierId: dossierId,
                userId: userId,
                point_list: pointList,
                visas_list: [],
                mode: "analyse", // Default to analyse mode
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            ).then(() => {
              console.log("Analysis request sent successfully");
            }).catch(error => {
              console.error("Error starting analysis:", error);

              // Update the dossier to indicate analysis failed
              const dossierDocRef = doc(db, "users", userId, "dossiers", dossierId);
              updateDoc(dossierDocRef, {
                isAnalysing: null
              });
            });
          })
          .catch(error => {
            console.error("Error fetching token:", error);
            this.$toast.error("Erreur lors de la récupération du token. Veuillez réessayer.");
          });

      } catch (error) {
        console.error('Error creating dossier:', error);
        alert('Erreur lors de la création du dossier.');
      }
    },

    scrollToBottom() {
      // Since we're not using a modal anymore, we might need to scroll the page
      window.scrollTo(0, document.body.scrollHeight);
    },

    editPoint(index) {
      this.pointsList[index].isEditing = true;
    },

    // Fetch subscription status
    async fetchSubscriptionStatus() {
      if (!auth.currentUser) {
        this.subscriptionStatus = "User not authenticated";
        this.loading = false;
        return;
      }

      const userId = auth.currentUser.uid;
      const subscriptionsRef = collection(db, `customers/${userId}/subscriptions`);
      const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));

      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          // Assuming the first active/trialing subscription is the current one
          const subscription = querySnapshot.docs[0].data();
          this.isSubscribed = subscription.status === "active";
          this.trialing = subscription.status === "trialing";
        } else {
          console.log("No active/trialing subscription found");
          this.isSubscribed = false;
          this.trialing = false;
        }
      } catch (error) {
        this.isSubscribed = false;
        this.trialing = false;
        this.showModal = true; // Show modal on error
        console.error('Error fetching subscription:', error);
      }
    },

    // Close the subscription modal
    closeModal() {
      this.showModal = false;
    },

    // Handle clicks outside modals if necessary
    // handleClickOutside(event) {
    //   // Implementation if needed
    // }
  }
};
</script>

<style scoped>
.content {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  background-color: #F5F6FA;
  min-height: 100vh;
}

.search-section {
  place-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.search-title {
  font-size: 20px;
  font-weight: bold;
}

.highlight {
  color: #625CFB;
}

.main-section {
  width: 80%;
  max-width: 875px;
  margin: 0 auto;
}

.search-bar {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 25px;
  box-shadow:
    0 -2px 4px rgba(0, 0, 0, 0.01),
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 6px 12px rgba(0, 0, 0, 0.05);
  padding: 15px 20px;
}

.input-container {
  width: 100%;
}

.search-input:focus {
  outline: none;
  box-shadow: none;
}

.search-input {
  width: 100%;
  min-height: 20px;
  max-height: 300px;
  padding: 10px;
  border: none;
  border-radius: 15px;
  outline: none;
  font-size: 16px;
  resize: none;
  background-color: white;
  overflow-y: hidden; /* Hide scrollbar */
  color: #000000; /* Making input text black */
}

/* Styling for the placeholder text */
.search-input::placeholder {
  color: rgba(0, 0, 0, 0.35); /* Light and transparent gray */
  opacity: 1; /* Needed for Firefox */
}

.buttons-line {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.button-base {
  padding: 8px 25px;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outline-button {
  background-color: white;
  color: #6c63ff;
  font-size: 0.8em;
  border: 1px solid #6c63ff;
}

.search-icon {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.align-button {
  justify-self: right;
}

.tech-purple {
  background-color: #6c63ff;
  color: white;
}

.tech-purple:hover{
  background-color: #5a54d1;
}

.outline-button:hover {
  background-color: #ffffff;
  color: #6c63ff;
}

.search-button:hover {
  background-color: #5a54d1;
  color: white;
}

.search-button img {
  margin-left: 5px;
}

.researchs, .synthese-container {
  background-color: #E9EFF4;
  padding: 20px 50px 25px;
  border-radius: 8px;
  background: linear-gradient(to right, #E9EFF4, #E2FAFFCC);
  box-shadow:
    0 -2px 4px rgba(0, 0, 0, 0.01),
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 6px 12px rgba(0, 0, 0, 0.05);
}

.card {
  font-size: 0.8rem;
  background-color: white;
  border-radius: 20px;
  box-shadow:
    0 -2px 4px rgba(0, 0, 0, 0.01),
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 6px 12px rgba(0, 0, 0, 0.05);
  color: #000;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.more-icon {
  width: 20px;
  cursor: pointer;
}

/* Spinner styles */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #6c63ff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Progress bar container */
.progress-bar-container {
  width: 100%;
  margin-top: 15px;
}

.progress {
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  background-color: #6c63ff;
}

/* Loading message animation */
.loading-message {
  color: #6c63ff;
  font-style: italic;
  text-align: center;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

/* Styling from SyntheseModal */
.synthese-content {
  padding: 20px;
  font-family: "Roboto", sans-serif;
}

.textarea-content {
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.enrichir-button {
  background-color: #6c63ff;
  color: white;
  border: none;
  padding: 8px 25px;
  border-radius: 25px;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enrichir-button:hover {
  background-color: #5a54d1;
}

.back-button {
  background-color: white;
  color: #6c63ff;
  border: 1px solid #6c63ff;
  padding: 8px 25px;
  border-radius: 25px;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-button {
  /* margin-left: 5px; */
  height: 50%;
  width: 13%;
  padding: 4px 8px;
  cursor: pointer;
}

.small-button:hover {
  background-color: #e0e0e0;
}

.point-item {
  margin-bottom: 2em;
}

.points-list {
  list-style-type: none;
  padding: 0;
}

.point-input {
  width: 80%;
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.point-text {
  padding: 1em;
  padding-right: 0;
  display: flex;
  align-items: center;
  border-radius: 16px;
  font-size: 1.1rem;
  background-color: #f8f9fa;
  margin-right: 10px;
  flex: 1;
}

.point-display {
  display: flex;
  align-items: center;
  flex: 1;
}

.title-points {
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.sources-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.source-item {
  margin: 0;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  font-size: 0.6rem;
  border-radius: 15px;
  cursor: pointer;
}

.button-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Fade transition animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
