<template>
  <div id="app">
    <nav v-if="isNavbarVisible" class="my-nav">
      <a class="my-nav-brand" href="#">
        <img src="@/assets/logo.svg" alt="Query Logo" class="my-nav-logo">
      </a>
      <ul class="my-nav-links">
        <li>
          <router-link class="my-nav-link" :to="{ name: 'dashboardPage' }" active-class="active">Mes recherches</router-link>
        </li>
        <li>
          <router-link class="my-nav-link" :to="{ name: 'contactPage' }">Nous contacter</router-link>
        </li>
        <li>
          <a class="my-nav-link disabled" href="#"></a>
        </li>
      </ul>
      <div class="my-nav-settings">
        <div class="settings-dropdown">
          <a class="my-nav-link" href="#" @click.prevent="toggleSettingsMenu">
            <img src="@/assets/settings.svg" alt="Settings Icon" class="my-nav-icon">
          </a>
          <div class="settings-menu" v-if="showSettingsMenu">
            <button class="signout-button" @click="handleSignout">
              Se déconnecter
            </button>
          </div>
        </div>
      </div>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
import { auth } from "@/firebase/firebaseConfig";
import { signOut } from "firebase/auth";

export default {
  name: 'App',
  data() {
    return {
      showSettingsMenu: false
    };
  },
  computed: {
    isNavbarVisible() {
      // List of routes where navbar should be visible
      const navbarRoutes = ['dashboard'];

      // Or check by path
      // const navbarPaths = ['/', '/contact', '/dashboard'];
      // return navbarPaths.includes(this.$route.path);
      console.log(this.$route.name)

      return navbarRoutes.includes(this.$route.name);
    }
  },
  methods: {
    toggleSettingsMenu() {
      this.showSettingsMenu = !this.showSettingsMenu;
    },
    async handleSignout() {
      try {
        await signOut(auth);
        console.log("User signed out successfully");
        // Close the settings menu
        this.showSettingsMenu = false;
        // Redirect to home page
        this.$router.push('/');
      } catch (error) {
        console.error("Error signing out:", error);
      }
    },
    closeSettingsMenu(event) {
      // Close the settings menu when clicking outside
      if (this.showSettingsMenu && !event.target.closest('.settings-dropdown')) {
        this.showSettingsMenu = false;
      }
    }
  },
  mounted() {
    // Add event listener to close dropdown when clicking outside
    document.addEventListener('click', this.closeSettingsMenu);
  },
  beforeDestroy() {
    // Remove event listener
    document.removeEventListener('click', this.closeSettingsMenu);
  }
};
</script>

<style scoped>
/* Global resets and font-family rules */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

#app {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Navigation */
.my-nav {
  display: flex;
  align-items: center;
  background-color: #120D31;
  padding: 0.5rem 1rem;
}

.my-nav-brand {
  margin-right: 125px;
  text-decoration: none;
}

.my-nav-logo {
  height: 50px;
}

/* Link list */
.my-nav-links {
  display: flex;
  flex: 1;             /* allows the ul to take remaining space */
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.my-nav-links li {
  margin-right: 1.5rem;
  font-weight: 400;
}

/* Base style for nav links */
.my-nav-link {
  position: relative;
  color: #FFFFFF;
  text-decoration: none;
  font-weight: 400;
  font-size: 0.8em;
  padding: 0.5rem 0;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Underline effect */
.my-nav-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: #FFFFFF;
  transition: width 0.3s ease;
}

/* Hover state */
.my-nav-link:hover {
  transform: translateY(-3px);
  color: #e0e0e0;
}

.my-nav-link:hover::after {
  width: 100%;
}

/* Disabled state remains unchanged */
.my-nav-link.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.my-nav-settings {
  margin-left: auto;
}

.my-nav-icon {
  height: 45px;
  width: 25px;
}

/* Settings dropdown */
.settings-dropdown {
  position: relative;
}

.settings-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 180px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  z-index: 1000;
  overflow: hidden;
}

.signout-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border: none;
  background-color: #FFFFFF;
  color: #333333;
  text-align: left;
  font-size: 0.9em;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.signout-button:hover {
  background-color: #F5F5F5;
}

/* Content area */
.content {
  height: calc(100vh - 70px); /* Adjust based on your navbar height */
  overflow-y: auto;
}

/* Example of the ellipse elements (if needed) */
.left-elipse {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100px;
  height: auto;
}

.left-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 180px;
  height: auto;
}

.right-elipse {
  position: absolute;
  top: 50%;
  right: 0;
  width: 100px;
  height: auto;
}
</style>
