<template>
  <div class="auth-container">
    <div class="auth-card">
      <div>
        <div class="logo-container">
          <img src="@/assets/query-img.svg" alt="Logo" />
        </div>

        <div class="verification-content">
          <h5 class="form-title">Vérification</h5>

          <div v-if="verified" class="verification-message">
            <div class="success-icon">
              <svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#6c63ff" fill-opacity="0.2"/>
                <path d="M8 12L11 15L16 10" stroke="#6c63ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p class="success-text">Votre email a bien été vérifié!</p>
            <p class="sub-text">Vous pouvez maintenant accéder à toutes les fonctionnalités.</p>
          </div>

          <div v-if="error" class="verification-message error">
            <p class="error-text">Une erreur s'est produite</p>
            <p class="sub-text">{{ errorMessage }}</p>
          </div>

          <a v-if="verified" href="https://www.query-juriste.com/dashboardPage" class="auth-button primary-button">
            Commencer maintenant
          </a>

          <div class="divider"></div>

          <p class="signup-text">
            <router-link to="/loginPage">Retour à la connexion</router-link>
          </p>
        </div>
      </div>
      <div class="copyright">© Query Juriste 2025</div>
    </div>
  </div>
</template>

<script>
import { getAuth, applyActionCode } from 'firebase/auth';

export default {
  data() {
    return {
      verified: false,
      error: false,
      errorMessage: ''
    };
  },
  mounted() {
    const auth = getAuth();
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const actionCode = urlParams.get('oobCode');

    if (mode === 'verifyEmail') {
      applyActionCode(auth, actionCode)
        .then(() => {
          this.verified = true;
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message;
        });
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.auth-container {
  display: flex;
  justify-content: center;
  background: url("@/assets/sign-In-bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.auth-card {
  width: 500px;
  padding: 0 80px;
  background-color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo-container {
  width: 100%;
  height: 130px;
  background: black;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verification-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.form-title {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  font-size: 20px;
  color: #333;
}

.verification-message {
  margin: 30px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-icon {
  margin-bottom: 20px;
}

.success-text {
  font-size: 18px;
  font-weight: 500;
  color: #6c63ff;
  margin-bottom: 10px;
}

.error-text {
  font-size: 18px;
  font-weight: 500;
  color: #ff6c6c;
  margin-bottom: 10px;
}

.sub-text {
  font-size: 14px;
  color: #666;
}

.auth-button {
  width: 100%;
  padding: 12px 0;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s;
}

.primary-button {
  background-color: #6c63ff;
  color: white;
}

.primary-button:hover {
  background-color: #5a54d1;
}

.divider {
  height: 1px;
  background-color: #e0e0e0;
  width: 100%;
  margin: 30px 0;
}

.signup-text {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
}

.signup-text a {
  color: #6c63ff;
  text-decoration: none;
}

.signup-text a:hover {
  text-decoration: underline;
}

.copyright {
  font-size: 12px;
  margin-bottom: 40px;

  text-align: center;
  color: #666;
}

@media (max-width: 768px) {
  .auth-card {
    width: 90%;
    padding: 0 30px;
  }

  .logo-container {
    height: 100px;
  }

  .success-text,
  .error-text {
    font-size: 16px;
  }

  .sub-text {
    font-size: 12px;
  }
}
</style>
