<template>
  <div class="auth-container">
    <div class="auth-card">
      <div>
        <div class="logo-container">
          <img src="@/assets/query-img.svg" alt="Logo" />
        </div>

        <div class="verification-content">
          <h5 class="form-title">Vérification de l'e-mail</h5>

          <div class="verification-message">
            <p>Vous devez vérifier votre e-mail avant de pouvoir continuer.</p>
            <p class="sub-text">Veuillez vérifier votre boîte de réception et cliquer sur le lien de vérification.</p>
          </div>

          <button @click="resendEmail" class="auth-button primary-button">
            Renvoyer l'e-mail de vérification
          </button>

          <div class="divider"></div>

          <p class="signup-text">
            <router-link to="/loginPage">Retour à la connexion</router-link>
          </p>
        </div>
      </div>
      <div class="copyright">© Query Juriste 2025</div>
    </div>
  </div>
</template>

<script>
import { resendVerificationEmail } from '@/firebase/auth';

export default {
  methods: {
    resendEmail() {
      resendVerificationEmail().then(() => {
        alert('Verification email has been resent. Please check your inbox.');
      }).catch(error => {
        alert('Error resending the email: ' + error.message);
      });
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.auth-container {
  display: flex;
  justify-content: center;
  background: url("@/assets/sign-In-bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.auth-card {
  width: 500px;
  padding: 0 80px;
  background-color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo-container {
  width: 100%;
  height: 130px;
  background: black;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verification-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.form-title {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  font-size: 20px;
  color: #333;
}

.verification-message {
  margin: 30px 0;
  text-align: center;
}

.verification-message p {
  margin-bottom: 15px;
  font-size: 16px;
  color: #333;
}

.sub-text {
  font-size: 14px;
  color: #666;
}

.auth-button {
  width: 100%;
  padding: 12px 0;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 15px;
  transition: background-color 0.3s;
}

.primary-button {
  background-color: #6c63ff;
  color: white;
}

.primary-button:hover {
  background-color: #5a54d1;
}

.divider {
  height: 1px;
  background-color: #e0e0e0;
  width: 100%;
  margin: 30px 0;
}

.signup-text {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
}

.signup-text a {
  color: #6c63ff;
  text-decoration: none;
}

.signup-text a:hover {
  text-decoration: underline;
}

.copyright {
  font-size: 12px;
  margin-bottom: 40px;
  text-align: center;
  color: #666;
}

@media (max-width: 768px) {
  .auth-card {
    width: 90%;
    padding: 0 30px;
  }

  .logo-container {
    height: 100px;
  }

  .verification-message p {
    font-size: 14px;
  }

  .sub-text {
    font-size: 12px;
  }
}
</style>
